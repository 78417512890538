// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CustomSelect, StyledLink } from 'components/shared';
import { EMPTY_UUID } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialAccounts } from 'gql/queries';

// Local Dependencies
import { SelectOption } from './CustomSelect';

// Local Typings
export interface FormValues {
  isActive: boolean;
  label: string;
  priceInCents: string | number;
  schoolYearEnding: number;
}

interface Props {
  includeAllOption?: boolean;
  name?: string;
  readOnly?: boolean;
}

// Local Variables
export const allOptionId = EMPTY_UUID;

// Component Definition
const FinancialAccountSelect = ({
  includeAllOption = false,
  name = 'financialAccountId',
  readOnly,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    data,
    loading,
  } = useGetFinancialAccounts();

  const canWriteFinancialAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  const handleClickAddFinancialAccount = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ACCOUNTS_NEW}`);
  }, [navigate]);

  const helperText = useMemo(() => {
    if (loading) {
      return undefined;
    }

    if (canWriteFinancialAccounts && !data?.financialAccounts.length) {
      return (
        <StyledLink onClick={handleClickAddFinancialAccount}>
          Add financial account
        </StyledLink>
      );
    }

    if (!canWriteFinancialAccounts && !data?.financialAccounts.length) {
      return 'Please ask a director with permission to add accounts to create a financial account.';
    }

    return undefined;
  }, [
    canWriteFinancialAccounts,
    data?.financialAccounts,
    handleClickAddFinancialAccount,
    loading,
  ]);

  const options = useMemo<SelectOption[] | undefined>(() => {
    if (includeAllOption) {
      return [
        {
          id: allOptionId,
          label: 'All Financial Accounts',
        },
        ...(data?.financialAccounts ?? []).map((account) => ({
          id: account.id,
          label: account.label,
        })),
      ];
    }

    return data?.financialAccounts.map((account) => ({
      id: account.id,
      label: account.label,
    }));
  }, [data?.financialAccounts, includeAllOption]);

  return (
    <>
      <CustomSelect
        disabled={!data?.financialAccounts.length}
        inputProps={{
          readOnly,
        }}
        isLoading={loading}
        label="Financial Account"
        name={name}
        options={options}
        required
      />

      {helperText && (
        <Box
          // This lines up with the error message label
          marginLeft={1.5}
          marginY={1}
        >
          <Typography>
            {helperText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FinancialAccountSelect;
