// External Dependencies
import { Form, Formik } from 'formik';
import { FormBlockTypes } from '@presto-assistant/api_types';
import {
  KeyboardEvent, MouseEvent, useCallback, useMemo, useState,
} from 'react';
import { darken, lighten } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard, Flex, SaveButton,
} from 'components/shared';
import { useCreateFormBlock } from 'gql/mutations/form-mutations';

// Local Dependencies
import NewBlockFormInputs, { FormValues } from './NewBlockFormInputs';

// Local Typings
interface StyledWrapperProps {
  $alwaysShow?: boolean;
}

interface Props {
  alwaysShow?: boolean;
  blockPosition: number;
  formId: string;
  newBlockPosition: number | null;
  setNewBlockPosition: (position: number | null) => void;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)({
  '&.add-new-card': {
    border: undefined,
  },
  '&.new-block-card': {
    border: 0,
    position: 'relative',
  },
});

const StyledWrapper = styled.div<StyledWrapperProps>(({
  $alwaysShow,
  theme,
}) => {
  const expandedPadding = 8;

  return {
    '&.new-block-button': {
      '&:focus, &:hover': {
        margin: theme.spacing(1, 0),
        opacity: 0.8,
        padding: expandedPadding,
      },

      '&:hover': {
        backgroundColor: lighten(theme.palette.prestoPrimaryUltraLight, 0.8),
        color: darken(theme.palette.text.secondary, 0.3),
      },

      border: `1px dashed ${theme.palette.grey[600]}`,
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      margin: $alwaysShow ? theme.spacing(1, 0) : 0,
      opacity: $alwaysShow ? 0.6 : 0,
      padding: $alwaysShow ? expandedPadding - 2 : 0,
      textAlign: 'center',
      transition: '250ms',
      transitionDelay: '150ms', // Delay the transition so the UI doesn't feel jumpy
    },
  };
});

// Component Definition
const NewBlockButton = ({
  alwaysShow,
  blockPosition,
  formId,
  newBlockPosition,
  setNewBlockPosition,
}: Props): JSX.Element => {
  const [hasSelectedType, setHasSelectedType] = useState(false);

  const isAddingNewBlock = newBlockPosition === blockPosition;

  const handleClear = useCallback((cb?: () => void) => {
    setNewBlockPosition(null);
    setHasSelectedType(false);
    cb?.();
  }, [setNewBlockPosition]);

  const [
    createFormBlock,
    {
      loading: isCreatingFormBlock,
    },
  ] = useCreateFormBlock({
    onCompleted: () => {
      handleClear();
    },
  });

  const initialValues = useMemo<FormValues>(() => ({
    adultInitials: undefined,
    adultSignature: undefined,
    content: '',
    formBlockTypeId: FormBlockTypes.Heading,
    formId,
    isRequired: false,
    label: '',
    options: [],
    position: blockPosition,
    s3Filename: '',
    s3Url: '',
  }), [blockPosition, formId]);

  const handleFormikSubmit = useCallback((values: FormValues) => {
    const {
      adultInitials,
      adultSignature,
      content,
      options,
      s3Filename,
      s3Url,
      ...restValues
    } = values;

    const metadata: Record<string, unknown> = {};

    if (content) {
      metadata.content = content;
    }

    if (options.length) {
      metadata.options = options;
    }

    if (adultInitials !== undefined) {
      metadata.adultInitials = adultInitials;
    }

    if (adultSignature !== undefined) {
      metadata.adultSignature = adultSignature;
    }

    if (s3Url) {
      metadata.s3Url = s3Url;
    }

    if (s3Filename) {
      metadata.s3Filename = s3Filename;
    }

    if (hasSelectedType) {
      createFormBlock({
        variables: {
          input: {
            ...restValues,
            formBlockTypeId: Number(restValues.formBlockTypeId),
            formId,
            metadata: JSON.stringify(metadata),
          },
        },
      });
    }
  }, [createFormBlock, formId, hasSelectedType]);

  const handleClick = useCallback(() => {
    setNewBlockPosition(blockPosition);
  }, [blockPosition, setNewBlockPosition]);

  const handleClickNext = useCallback((evt: MouseEvent) => {
    evt.preventDefault();

    setHasSelectedType(true);
  }, []);

  const handleKeyDown = useCallback((evt: KeyboardEvent) => {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();

      setNewBlockPosition(blockPosition);
    }
  }, [blockPosition, setNewBlockPosition]);

  return (
    <StyledEnhancedCard
      className={clsx('new-block-card', isAddingNewBlock && 'add-new-card')}
    >
      <Collapse
        in={isAddingNewBlock}
        sx={{ visibility: isAddingNewBlock ? 'inherit' : 'hidden' }}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleFormikSubmit}
        >
          {({ handleSubmit, resetForm, values }) => (
            <Form onSubmit={handleSubmit}>
              <CardContent>
                <NewBlockFormInputs
                  hasSelectedType={hasSelectedType}
                  values={values}
                />
              </CardContent>

              <CardActions>
                <Flex
                  gap={1}
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Button onClick={() => handleClear(resetForm)}>
                    Cancel
                  </Button>

                  <SaveButton
                    disabled={!hasSelectedType && !values.formBlockTypeId}
                    isSaving={isCreatingFormBlock}
                    onClick={hasSelectedType ? undefined : handleClickNext}
                    type={hasSelectedType ? 'submit' : 'button'}
                    variant={hasSelectedType ? 'contained' : 'outlined'}
                  >
                    {hasSelectedType ? 'Save' : 'Next'}
                  </SaveButton>
                </Flex>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Collapse>

      <Collapse in={!isAddingNewBlock}>
        <StyledWrapper
          $alwaysShow={alwaysShow}
          className="new-block-button"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <Typography
            color="textSecondary"
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
            }}
          >
            <AddIcon
              color="inherit"
              fontSize="inherit"
            />
            New Block
          </Typography>
        </StyledWrapper>
      </Collapse>
    </StyledEnhancedCard>
  );
};

export default NewBlockButton;
