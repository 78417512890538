// External Dependencies
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import AdminIcon from 'mdi-material-ui/ShieldAccount';
import ArrowRightThickIcon from 'mdi-material-ui/ArrowRightThick';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  ListItemWithSecondaryAction,
} from 'components/shared';
import { dfaMemberIdPlaceholder } from 'utils/api/auth';
import { formatDateTime } from 'utils';
import { toggleOrganization } from 'utils/api';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParseToken } from 'utils/cookies';
import SsoSignInDialog from 'components/shared/SsoSignInDialog';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
})) as typeof Typography;

const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const FindOrganization = (): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [switchToOrganizationError, setSwitchToOrganizationError] = useState('');
  const [switchToMemberId, setSwitchToMemberId] = useState<string | null>(null);
  const [switchToMemberSsoTypeId, setSwitchToMemberSsoTypeId] = useState<number | null>(null);

  const dispatch = useDispatch();

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  const { self } = useSelfQuery();

  const token = useParseToken();

  const ssoTypeIdForToken = token?.districtSsoTypeId;
  const ssoTenantIdForToken = token?.ssoTenantId;

  const handleCloseSsoDialog = useCallback(() => {
    setSwitchToMemberId(null);
    setSwitchToMemberSsoTypeId(null);
  }, []);

  const toggleOrg = useCallback(({
    isAdmin,
    memberId,
    ssoTenantId,
    ssoTypeId,
  }: {
    isAdmin: boolean;
    memberId: string | null;
    ssoTenantId: string | null;
    ssoTypeId: number | null;
  }) => async () => {
    try {
      setIsLoading(true);

      const nextPath = '';
      const suppressErrorNotification = true;

      const requiresSso = Boolean(ssoTypeId
        && ssoTenantId
        && ssoTypeId !== ssoTypeIdForToken
        && ssoTenantId !== ssoTenantIdForToken
        && isAdmin);

      if (!requiresSso && memberId !== self?.id) {
        const res = await toggleOrganization(
          memberId,
          dispatch,
          nextPath,
          suppressErrorNotification,
        );

        if (res?.error) {
          setSwitchToOrganizationError(res.error);
        }
      }

      if (requiresSso && memberId !== self?.id) {
        setSwitchToMemberId(memberId ?? dfaMemberIdPlaceholder);
        setSwitchToMemberSsoTypeId(ssoTypeId);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    dispatch,
    self?.id,
    ssoTenantIdForToken,
    ssoTypeIdForToken,
  ]);

  if (!self?.pendingOrganizationMemberships.length
    && !self?.userOrgData.length
    && !self?.districtMemberships.length) {
    return null;
  }

  const handleOpenDialog = (pendingOrgName: string) => {
    setOrgName(pendingOrgName);
    handleOpen();
  };

  const districtMembership = self?.districtMemberships?.[0];

  const ssoTypeIdForDistrictMembership = districtMembership?.district?.districtSsoTypeId;
  const ssoTenantIdForDistrictMemberdhip = districtMembership?.district?.ssoTenantId;

  return (
    <>
      <Box
        marginTop={4}
        maxWidth={600}
        width="auto"
      >
        {!!self?.pendingOrganizationMemberships?.length && (
          <>
            <StyledTypography
              component="h3"
              variant="h6"
            >
              Pending Membership Requests
            </StyledTypography>

            <StyledEnhancedCard>
              <CardContent>
                <>
                  <EnhancedAlert
                    sx={{
                      marginBottom: 3,
                      textAlign: 'left',
                    }}
                    title="Pending director approval"
                  >
                    Please check with your director regarding your membership status
                  </EnhancedAlert>

                  <List>
                    {self.pendingOrganizationMemberships.map((pendingMembership) => (
                      <ListItemWithSecondaryAction
                        key={pendingMembership.id}
                        primaryText={pendingMembership.organizationLabel}
                        secondaryAction={{
                          buttonIcon: <ArrowRightThickIcon />,
                          buttonSize: 'medium',
                          buttonText: 'View',
                          onClick: () => handleOpenDialog(pendingMembership.organizationLabel),
                        }}
                        secondaryText={(
                          <Typography
                            gutterBottom
                            variant="body2"
                          >
                            Requested at {formatDateTime(pendingMembership.requestedAt)}
                          </Typography>
                        )}
                      />
                    ))}
                  </List>
                </>
              </CardContent>
            </StyledEnhancedCard>
          </>
        )}

        {Boolean(self?.userOrgData?.length || districtMembership) && (
          <>
            <StyledTypography
              component="h3"
              sx={{ mt: 2 }}
              variant="h6"
            >
              Active Memberships
            </StyledTypography>

            <StyledEnhancedCard>
              <CardContent>
                <>
                  <EnhancedAlert
                    sx={{
                      marginBottom: 3,
                      textAlign: 'left',
                    }}
                    title="Get Started"
                  >
                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      Choose an organization below to get started.
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      The next time you sign in, you&apos;ll
                      be taken directly to a {APP_NAME} organization.
                    </Typography>
                  </EnhancedAlert>

                  <List>
                    {districtMembership && (
                      <ListItemWithSecondaryAction
                        key={districtMembership.district?.id ?? 'district'}
                        primaryText={districtMembership.district?.label}
                        secondaryAction={{
                          buttonIcon: <AdminIcon />,
                          buttonSize: 'medium',
                          buttonText: 'View',
                          disabled: isLoading,
                          onClick: toggleOrg({
                            isAdmin: true,
                            memberId: null,
                            ssoTenantId: ssoTenantIdForDistrictMemberdhip ?? null,
                            ssoTypeId: ssoTypeIdForDistrictMembership ?? null,
                          }),
                        }}
                        secondaryText="Admin"
                      />
                    )}

                    {self.userOrgData.map((userOrg) => {
                      const organizationDistrict = userOrg.organization.district;
                      const ssoTypeId = organizationDistrict?.districtSsoTypeId;
                      const ssoTenantId = organizationDistrict?.ssoTenantId;

                      return (
                        <ListItemWithSecondaryAction
                          key={userOrg.organizationId}
                          primaryText={userOrg.organization.label}
                          secondaryAction={{
                            buttonIcon: <ArrowRightThickIcon />,
                            buttonSize: 'medium',
                            buttonText: 'View',
                            disabled: isLoading,
                            onClick: toggleOrg({
                              isAdmin: userOrg.admin ?? false,
                              memberId: userOrg.member?.id ?? null,
                              ssoTenantId: ssoTenantId ?? null,
                              ssoTypeId: ssoTypeId ?? null,
                            }),
                          }}
                          secondaryText={`${userOrg.organization.entityType.label} ${userOrg.organization.organizationType.label}`}
                        />
                      );
                    })}
                  </List>
                </>

                <Collapse
                  className="errorContainer"
                  in={Boolean(switchToOrganizationError)}
                >
                  <EnhancedAlert
                    severity="error"
                    sx={{
                      marginTop: 2,
                      textAlign: 'left',
                    }}
                    title="Login Error"
                  >
                    {switchToOrganizationError}
                  </EnhancedAlert>
                </Collapse>

              </CardContent>
            </StyledEnhancedCard>
          </>
        )}
      </Box>

      <ConfirmationDialog
        confirmButtonAction={handleClose}
        description={(
          <>
            Please check with the director of <strong>{orgName}</strong>{' '}
            regarding your membership status.
          </>
        )}
        handleClose={handleClose}
        hideDeclineButton
        open={isOpen}
        title="Your membership is pending"
      />

      <SsoSignInDialog
        isOpen={Boolean(switchToMemberId && switchToMemberSsoTypeId)}
        onClose={handleCloseSsoDialog}
        ssoTypeId={switchToMemberSsoTypeId ?? undefined}
        switchToMemberId={switchToMemberId ?? undefined}
      />
    </>
  );
};

export default FindOrganization;
