// External Dependencies
import Chip from '@mui/material/Chip';

// Utils Definitions
export const renderInventoryRepairStatusCell = (actualReturnDate: Date | null) => {
  const color = actualReturnDate ? 'warning' : 'success';

  return (
    <Chip
      color={color}
      label={actualReturnDate ? 'Closed' : 'Open'}
      size="small"
      variant="outlined"
    />
  );
};
