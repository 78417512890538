/* eslint-disable graphql/template-strings */
// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useLazyQueryEnhanced, useQueryEnhanced } from 'utils/lib/graphql';

// Local Dependencies
import { FRAGMENT_PERMISSION_ACTIONS, FRAGMENT_USER_PERMISSIONS } from './user-queries';

export const GET_SELF = gql`
  query Self {
    self {
      addressOne
      addressTwo
      alertFlagId
      authUserEmail
      authUserId
      city
      dateOfBirth
      districtMemberships {
        district {
          districtSsoTypeId
          id
          label
          ssoTenantId
        }
        email
        firstName
        id
        lastName
        middleName
      }
      emailSignature
      encryptionKey
      state {
        abbreviation
        id
        label
      }
      stateId
      zipcode
      phoneNumber
      currentOrgActive
      currentOrgId
      gender {
        id
        label
      }
      hasCompletedOnboarding
      hasCustomPassword
      hasDistrict
      id
      isCreator
      email
      firstName
      lastName
      loggedInAt
      matchedUnclaimedMembers {
        email
        id
        firstName
        lastName
        organization {
          id
          label
        }
        role {
          id
          label
        }
      }
      middleName
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
      pendingOrganizationMemberships {
        id
        organizationLabel
        requestedAt
      }
      permissions {
        ...UserPermissionsFragment
      }
      primarySpokenLanguage {
        id
        label
      }
      role {
        canBeAdmin
        id
        label
      }
      title
      userOrgData {
        admin
        district {
          id
          label
        }
        id
        mayEditPermissions
        member {
          email
          firstName
          id
          lastName
          loggedInAt
          middleName
          role {
            id
            label
          }
        }
        organization {
          creatorId
          creator {
            id
            firstName
            lastName
            middleName
          }
          district {
            id
            districtSsoTypeId
            ssoTenantId
          }
          entityType {
            id
            label
          }
          hasStripeId
          id
          label
          organizationType {
            id
            label
          }
        }
        organizationId
      }
    }
  }
  ${FRAGMENT_PERMISSION_ACTIONS}
  ${FRAGMENT_USER_PERMISSIONS}
`;

const CLAIM_MEMBER_LOOKUP = gql`
  query ClaimMemberLookup($input: ClaimMemberInput!) {
    claimMemberLookup(input: $input) {
      district {
        id
        label
      }
      email
      firstName
      id
      lastName
      middleName
      organization {
         id
         label
      }
      role {
        canBeAdmin
        id
        label
      }
    }
  }
`;

export const useGetSelf = () => useQueryEnhanced<{ self: GQL.ISelf }>(
  GET_SELF,
);

export const useGetClaimMemberLookup = () =>
  useLazyQueryEnhanced<{ claimMemberLookup: GQL.IMember }, GQL.IClaimMemberLookupOnQueryArguments>(
    CLAIM_MEMBER_LOOKUP,
  );
