// External Dependencies
import { FieldArrayRenderProps } from 'formik';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { TransitionGroup } from 'react-transition-group';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedDialog,
  ListItemWithSecondaryAction,
  Select, ZeroState,
} from 'components/shared';
import { allOptionId } from 'components/shared/FinancialAccountSelect';
import { useGetFinancialItems } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  arrayHelpers: FieldArrayRenderProps;
  financialAccountIdFormikValue: string;
  itemsFormikValue: GQL.IFinancialFundraiserCreditItemPriorityInput[];
  schoolYearEndingFormikValue: number;
}

const ItemsFieldArray = ({
  arrayHelpers,
  financialAccountIdFormikValue,
  itemsFormikValue,
  schoolYearEndingFormikValue,
}: Props): JSX.Element => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  useEffect(() => {
    setSelectedItemId(null);
  }, [
    financialAccountIdFormikValue,
    schoolYearEndingFormikValue,
  ]);

  // make sure there are no duplicate priorities
  useEffect(() => {
    const existingPriorities = itemsFormikValue.map((item) => item.priority);

    const uniquePriorities = Array.from(new Set(existingPriorities));

    const range = Array.from({ length: uniquePriorities.length }, (_, index) => index + 1);

    if (existingPriorities.length !== uniquePriorities.length
      || !range.every((value) => uniquePriorities.includes(value))) {
      const updatedPriorities = itemsFormikValue.map((item, index) => ({
        ...item,
        priority: index + 1,
      }));

      arrayHelpers.form.setFieldValue('items', updatedPriorities);
    }
    // let's not pass in arrayHelpers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsFormikValue]);

  const {
    isOpen: isAddingItem,
    toggleIsOpen: toggleIsAddingItem,
  } = useIsOpen();

  const addItem = useCallback(() => {
    const objectToAdd: GQL.IFinancialFundraiserCreditItemPriorityInput = {
      financialItemId: selectedItemId ?? '',
      priority: itemsFormikValue.length + 1,
    };

    arrayHelpers.push(objectToAdd);

    toggleIsAddingItem();

    setSelectedItemId(null);
  }, [arrayHelpers, itemsFormikValue.length, toggleIsAddingItem, selectedItemId]);

  const handleChangeItem: SelectInputProps['onChange'] = useCallback((evt) => {
    setSelectedItemId(evt.target.value);
  }, []);

  const {
    data: financialItemsData,
    loading: isLoadingFinancialItems,
  } = useGetFinancialItems(schoolYearEndingFormikValue);

  const selectableItems = useMemo(
    () => {
      const selectedItemIds = itemsFormikValue.map((item) => item.financialItemId);

      return financialItemsData?.financialItems.data.filter(
        (item) =>
          (financialAccountIdFormikValue === allOptionId
            || item.financialAccount?.id === financialAccountIdFormikValue)
          && !selectedItemIds.includes(item.id)
          && item.canApplyFundraiserCredits,
      );
    },
    [
      financialAccountIdFormikValue,
      financialItemsData,
      itemsFormikValue,
    ],
  );

  const areAllItemsSelected = (selectableItems?.length ?? 0) === 0 && itemsFormikValue.length > 0;

  return (
    <EnhancedCard sx={{ marginTop: 2 }}>
      {!itemsFormikValue.length && (
        <EnhancedCardContent>
          <ZeroState
            excludeNeedHelp
            message="No items selected."
            padding={0}
          />
        </EnhancedCardContent>
      )}

      <List>
        <TransitionGroup>
          {itemsFormikValue.map((item, index) => {
            const isLast = index === itemsFormikValue.length - 1;

            const matchedItem = financialItemsData?.financialItems.data?.find(
              (i) => i.id === item.financialItemId,
            );

            const matchesAccountId = financialAccountIdFormikValue === allOptionId
              || matchedItem?.financialAccount?.id === financialAccountIdFormikValue;
            const matchesSchoolYear = matchedItem?.schoolYearEnding === schoolYearEndingFormikValue;

            let errorMessage: string | null = null;

            if (!matchesAccountId) {
              errorMessage = 'This item is not associated with the selected financial account.';
            } else if (!matchesSchoolYear) {
              errorMessage = 'This item is not associated with the selected school year.';
            }

            if (isLoadingFinancialItems) {
              errorMessage = null;
            }

            // This is a bit piecemeal. The List component has a built-in divider prop, but it
            // doesn't work well with our custom ListItemWithSecondaryAction component and an alert.
            return (
              <Collapse key={item.financialItemId}>
                <ListItemWithSecondaryAction
                  primaryText={matchedItem?.label}
                  secondaryAction={{
                    buttonIcon: <RemoveIcon />,
                    buttonText: 'Remove',
                    onClick: arrayHelpers.handleRemove(index),
                  }}
                  secondaryText={`Priority ${item.priority}`}
                />

                <Collapse in={Boolean(errorMessage)}>
                  <EnhancedCardContent>
                    <EnhancedAlert severity="error">
                      {errorMessage}
                    </EnhancedAlert>
                  </EnhancedCardContent>
                </Collapse>

                {!isLast && <Divider />}
              </Collapse>
            );
          })}
        </TransitionGroup>
      </List>

      {!areAllItemsSelected && (
        <CardActions>
          <Button
            disabled={(selectableItems?.length ?? 0) === 0}
            onClick={toggleIsAddingItem}
            size="small"
            variant="outlined"
          >
            Add Item
          </Button>
        </CardActions>
      )}

      <Collapse
        in={selectableItems?.length === 0
          && itemsFormikValue.length === 0
          && !isLoadingFinancialItems}
      >
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 2 }}
        >
          The selected account has no associated items that can be applied to fundraiser credits.
        </EnhancedAlert>
      </Collapse>

      <EnhancedDialog open={isAddingItem}>
        <DialogTitle>
          Select an item
        </DialogTitle>

        <DialogContent>
          <DialogContentText gutterBottom>
            Choose from the items for the selected financial account and school year.
          </DialogContentText>

          <Select
            label="Item"
            name="item"
            onChange={handleChangeItem}
            options={selectableItems ?? []}
            value={selectedItemId ?? ''}
          />

          <FormHelperText>
            Only items that can be applied to fundraiser credits are shown.
          </FormHelperText>
        </DialogContent>

        <DialogActions>
          <Button onClick={toggleIsAddingItem}>
            Cancel
          </Button>

          <Button
            onClick={addItem}
            variant="contained"
          >
            Add Item
          </Button>
        </DialogActions>
      </EnhancedDialog>
    </EnhancedCard>
  );
};

export default ItemsFieldArray;
