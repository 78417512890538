/* eslint-disable graphql/template-strings */
// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import {
  PeoplePickerTableResource,
} from 'state/table/actions';
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import {
  getIndexQueryParams,
  useLazyQueryEnhanced,
  useLazyQueryPromise,
  useQueryEnhanced,
} from 'utils/lib/graphql';
import { hasPermission } from 'state/self/selectors';
import { parseSearch } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

// Local Variables
const getAdditionalPeoplePickerVariables: UseGetTableQueryArgs['getAdditionalVariables'] = (search) => {
  const where: GQL.IPeoplePickerIndexWhere = {
    grade: search.grade ? [Number(search.grade)] : undefined,
    groupId: search.groupId ? [search.groupId] : null,
    primaryRoleId: search.primaryRoleId ? [search.primaryRoleId] : null,
    roleId: search.roleId,
  };

  return { where };
};

// Local Typings
export interface SimpleUser {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string | null;
}

export interface SimpleUserWithRole {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string | null;
  role: GQL.IRole;
}

export interface UserOrgRole {
  id: GQL.IUserOrgRole['id'];
  isPrimary: GQL.IUserOrgRole['isPrimary'];
  organizationRole: {
    id: GQL.IOrganizationRole['id'];
    label: GQL.IOrganizationRole['label'];
  };
}

export interface StudentData extends DynamicFields {
  addressOne: GQL.IUser['addressOne'];
  addressTwo: GQL.IUser['addressTwo'];
  allergies: GQL.IUser['allergies'];
  authUserEmail: GQL.IUser['authUserEmail'];
  authUserId: GQL.IUser['authUserId'];
  bouncedEmail: GQL.IUser['bouncedEmail'];
  city: GQL.IUser['city'];
  dateOfBirth: GQL.IUser['dateOfBirth'];
  email: GQL.IUser['email'];
  firstName: GQL.IUser['firstName'];
  gender: {
    id: GQL.IUserGender['id'];
    label: GQL.IUserGender['label'];
  } | null;
  grade: GQL.IUser['grade'];
  groups: GQL.IUser['groups'];
  id: GQL.IUser['id'];
  isEligible: GQL.IUser['isEligible'];
  lastName: GQL.IUser['lastName'];
  loggedInAt: GQL.IUser['loggedInAt'] | null;
  middleName: GQL.IUser['middleName'] | null;
  otherGenderLabel: GQL.IUser['otherGenderLabel'];
  phoneNumber: GQL.IUser['phoneNumber'];
  previousCampus: GQL.IUser['previousCampus'];
  primarySpokenLanguage: {
    id: GQL.ISpokenLanguage['id'];
    label: GQL.ISpokenLanguage['label'];
  }
  privateLessonTeacher: GQL.IUser['privateLessonTeacher'] | null;
  role: GQL.IUser['role'];
  secondaryEmail: GQL.IUser['secondaryEmail'];
  secondaryPhoneNumber: GQL.IUser['secondaryPhoneNumber'];
  shirtSize: GQL.IUser['shirtSize'];
  state: GQL.IUser['state']
  stateId: GQL.IUser['stateId'];
  studentInfo: GQL.IUser['studentInfo'];
  successorOrganization: GQL.IUser['successorOrganization'] | null;
  userOrgData: {
    id: GQL.IUserOrganization['id'];
    member?: GQL.IUserOrganization['member'];
    receivedWelcomeEmailAt: GQL.IUserOrganization['receivedWelcomeEmailAt'] | null;
    roles: UserOrgRole[];
  } | null;
  zipcode: GQL.IUser['zipcode'];
}

export interface AdultData extends DynamicFields {
  addressOne: GQL.IUser['addressOne'];
  addressTwo: GQL.IUser['addressTwo'];
  allergies: GQL.IUser['allergies'];
  authUserEmail: GQL.IUser['authUserEmail'];
  authUserId: GQL.IUser['authUserId'];
  city: GQL.IUser['city'];
  dateOfBirth: GQL.IUser['dateOfBirth'];
  email: GQL.IUser['email'];
  firstName: GQL.IUser['firstName'];
  gender: {
    id: GQL.IGender['id'];
    label: GQL.IGender['label'];
  } | null;
  id: GQL.IUser['id'];
  lastName: GQL.IUser['lastName'];
  loggedInAt: GQL.IUser['loggedInAt'] | null;
  middleName: GQL.IUser['middleName'] | null;
  phoneNumber: GQL.IUser['phoneNumber'];
  primarySpokenLanguage: {
    id: GQL.ISpokenLanguage['id'];
    label: GQL.ISpokenLanguage['label'];
  }
  role: GQL.IUser['role'];
  secondaryEmail: GQL.IUser['secondaryEmail'];
  secondaryPhoneNumber: GQL.IUser['secondaryPhoneNumber'];
  shirtSize: GQL.IUser['shirtSize'];
  state: GQL.IUser['state']
  stateId: GQL.IUser['stateId'];
  title?: GQL.IUser['title'];
  userOrgData: {
    id: GQL.IUserOrganization['id'];
    roles: UserOrgRole[];
  } | null;
  zipcode: GQL.IUser['zipcode'];
}

interface SuppressedEmailIndexResponse {
  suppressedEmails: GQL.ISuppressedEmailsIndexAll;
}
interface DirectorsIndexResponse {
  directorsIndex: GQL.IDirectorsIndexAll;
}

interface DirectorsIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
}
interface DistrictAdminDirectorsResponse {
  districtDirectorsIndex: GQL.IDistrictDirectorsIndexAll;
}

interface DistrictAdminDirectorsVariables {
  organizationId: string;
  queryParams?: Omit<GQL.IIndexQueryParams, 'exportReport'>;
}

interface DistrictAdminParentsWithPermissionsResponse {
  districtParentsWithPermissionsIndex: GQL.IDistrictParentsWithPermissionsIndexAll;
}
interface DistrictAdminParentsWithPermissionsVariables {
  organizationId: string;
  queryParams?: Omit<GQL.IIndexQueryParams, 'exportReport'>;
}

interface InactiveIndexResponse {
  inactiveUsersIndex: GQL.IInactiveUsersIndexAll;
}

interface ParentsIndexResponse {
  parentsIndex: GQL.IParentsIndexAll;
}

interface StudentsIndexResponse {
  studentsIndex: GQL.IStudentsIndexAll;
}

interface PeoplePickerIndexResponse {
  peoplePickerIndex: GQL.ISimpleUserAll;
}

interface PendingMembersIndexResponse {
  pendingMemberIndex: GQL.ISimpleUserAll;
}

interface GetAllMembersOptions {
  skip?: boolean;
}

// Fragment Definitions
export const FRAGMENT_SIMPLE_USER = gql`
  fragment SimpleUserFragment on SimpleUser {
    email
    firstName
    id
    lastName
    middleName
  }
`;

export const FRAGMENT_SIMPLE_USER_WITH_ROLE = gql`
  fragment SimpleUserWithRoleFragment on SimpleUser {
    email
    firstName
    id
    lastName
    middleName
    role {
      id
      label
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    addressOne
    addressTwo
    allergies
    authUserEmail
    authUserId
    bouncedEmail {
      bouncedAt
      email
      id
      reason
      suppressedAt
    }
    city
    dateOfBirth
    email
    firstName
    gender {
      id
      label
    }
    grade
    groups {
      id
      label
    }
    id
    isEligible
    lastName
    loggedInAt
    middleName
    otherGenderLabel
    phoneNumber
    previousCampus
    primarySpokenLanguage {
      id
      label
    }
    privateLessonTeacher
    role {
      id
      label
    }
    secondaryEmail
    secondaryPhoneNumber
    state {
      id
      label
    }
    shirtSize
    stateId
    studentInfo {
      studentId
    }
    successorOrganization {
      id
      label
    }
    zipcode
    userOrgData {
      id
      member {
        id
        loggedInAt
      }
      receivedWelcomeEmailAt
      roles {
        id
        isPrimary
        organizationRole {
          id
          label
        }
      }
    }

    dynamicField01
    dynamicField02
    dynamicField03
    dynamicField04
    dynamicField05
    dynamicField06
    dynamicField07
    dynamicField08
    dynamicField09
    dynamicField10
    dynamicField11
    dynamicField12
    dynamicField13
    dynamicField14
    dynamicField15
    dynamicField16
    dynamicField17
    dynamicField18
    dynamicField19
    dynamicField20
    dynamicField21
    dynamicField22
    dynamicField23
    dynamicField24
    dynamicField25
    dynamicField26
    dynamicField27
    dynamicField28
    dynamicField29
    dynamicField30
    dynamicField31
    dynamicField32
    dynamicField33
    dynamicField34
    dynamicField35
    dynamicField36
    dynamicField37
    dynamicField38
    dynamicField39
    dynamicField40
    dynamicField41
    dynamicField42
    dynamicField43
    dynamicField44
    dynamicField45
    dynamicField46
    dynamicField47
    dynamicField48
    dynamicField49
    dynamicField50
  }
`;

export const FRAGMENT_PERMISSION_ACTIONS = gql`
  fragment PermissionActionFragment on PermissionActions {
    edit {
      allowed
      permissionId
    }
    delete {
      allowed
      permissionId
    }
    read {
      allowed
      permissionId
    }
    write {
      allowed
      permissionId
    }
  }
`;

export const FRAGMENT_USER_PERMISSIONS = gql`
  fragment UserPermissionsFragment on Permissions {
    emailMembers { ...PermissionActionFragment }
    finances { ...PermissionActionFragment }
    financialAccounts { ...PermissionActionFragment }
    groups { ...PermissionActionFragment }
    inventory { ...PermissionActionFragment }
    inventoryCheckouts { ...PermissionActionFragment }
    inventoryDynamicFields { ...PermissionActionFragment }
    inventoryLocationInfo { ...PermissionActionFragment }
    inventoryPurchaseInfo { ...PermissionActionFragment }
    inventoryRepairs { ...PermissionActionFragment }
    inventoryStatusInfo { ...PermissionActionFragment }
    library { ...PermissionActionFragment }
    payments { ...PermissionActionFragment }
    s3Uploads { ...PermissionActionFragment }
    users { ...PermissionActionFragment }
    uniforms { ...PermissionActionFragment }
    uniformCheckouts { ...PermissionActionFragment }
  }
`;

// Query Definitions
const ALL_DIRECTORS = gql`
  query AllDirectors {
    allDirectors {
      email
      firstName
      id
      lastName
    }
  }
`;

const ALL_MEMBERS = gql`
  query AllMembers {
    allMembers {
      email
      id
      firstName
      grade
      groups {
        id
        label
      }
      isEligible
      lastName
      primaryRole {
        id
        label
      }
      roleLabel
      secondaryRoles {
        id
        label
      }
      studentId
      successorOrganization {
        id
        label
      }

      # member_finances materialized view data
      balanceDueInCents
      creditsTotalInCents
      feesAssignedInCents
      paymentsTotalInCents
      previousYearsBalanceDueInCents
    }
  }
`;

export const GET_NON_DIRECTORS = gql`
  query NonDirectors(
    $queryParams: QueryParams
    $where: UserWhere
  ) {
    nonDirectors(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        email
        firstName
        grade
        id
        lastName
        loggedInAt
        mayEditPermissions
        middleName
        role {
          id
          label
        }
      }
      fullCount
    }
  }
`;

export const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  },
  ${USER_FRAGMENT}
`;

export const GET_USER_BY_DISTRICT_ADMIN = gql`
  query UserByDistrictAdmin(
    $id: ID!
    $organizationId: ID!
  ) {
    userByDistrictAdmin(
      id: $id
      organizationId: $organizationId
    ) {
      addressOne
      addressTwo
      city
      dateOfBirth
      currentOrgId
      email
      firstName
      gender {
        id
        label
      }
      grade
      id
      isEmailVerified
      lastName
      mayEditPermissions
      middleName
      otherGenderLabel
      permissions {
        ...UserPermissionsFragment
      }
      phoneNumber
      primarySpokenLanguage {
        id
        label
      }
      shirtSize
      stateId
      state {
        id
        label
      }
      studentInfo {
        studentId
      }
      zipcode
    }
  }
  ${FRAGMENT_PERMISSION_ACTIONS}
  ${FRAGMENT_USER_PERMISSIONS}
`;

export const GET_USERS_BY_IDS = gql`
  query UsersByIds($ids: [ID!]!) {
    usersByIds(ids: $ids) {
      email
      id
      firstName
      lastName
      middleName
    }
  }
`;

export const GET_PEOPLE_PICKER_INDEX = gql`
  query PeoplePickerIndex(
    $queryParams: IndexQueryParams
    $where: PeoplePickerIndexWhere
  ) {
    peoplePickerIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        ...SimpleUserWithRoleFragment
      }
      fullCount
    }
  }
  ${FRAGMENT_SIMPLE_USER_WITH_ROLE}
`;

export const GET_PEOPLE_PICKER_INDEX_ALL = gql`
  query PeoplePickerIndexAll(
    $queryParams: IndexQueryParams
    $where: PeoplePickerIndexWhere
  ) {
    peoplePickerIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        id
      }
      fullCount
    }
  }
`;

export const GET_ADULTS_WITH_PERMISSIONS_BY_DISTRICT_ADMIN = gql`
  query AdultsWithPermissionByDistrictAdmin(
    $organizationId: ID!
    $queryParams: QueryParams
  ) {
    adultsWithPermissionsByDistrictAdmin(
      organizationId: $organizationId
      queryParams: $queryParams
    ) {
        fullCount
        data {
          id
          email
          firstName
          lastName
          mayEditPermissions
          middleName
          permissions {
            ...UserPermissionsFragment
          }
        }
      }
    }
    ${FRAGMENT_PERMISSION_ACTIONS}
    ${FRAGMENT_USER_PERMISSIONS}
`;

export const GET_DIRECTORS_INDEX = gql`
  query DirectorIndex(
    $queryParams: IndexQueryParams
  ) {
    directorsIndex(
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        middleName
        title
      }
      fullCount
    }
  }
`;

const GET_INACTIVE_USERS_INDEX = gql`
  query InactiveUsersIndex(
    $queryParams: IndexQueryParams
  ) {
    inactiveUsersIndex(
      queryParams: $queryParams
    ) {
      data {
        email
        firstName
        id
        isAdmin
        lastName
        middleName
        roleLabel
        userOrganizationId
      }
      fullCount
    }
  }
`;

export const GET_DISTRICT_ORGANIZATIONS_DIRECTORS_INDEX = gql`
  query DistrictDirectorsIndex(
    $organizationId: ID!
    $queryParams: IndexQueryParams
  ) {
    districtDirectorsIndex(
      organizationId: $organizationId
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        middleName
        title
      }
      fullCount
    }
  }
`;

export const GET_DISTRICT_ORGANIZATIONS_PARENTS_WITH_PERMISSIONS_INDEX = gql`
  query DistrictParentsWithPermissionsIndex(
    $organizationId: ID!
    $queryParams: IndexQueryParams
  ) {
    districtParentsWithPermissionsIndex(
      organizationId: $organizationId
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        middleName
        permissions
      }
      fullCount
    }
  }
`;

export const useGetParentsIndexQuery = () => {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  return gql`
    query ParentIndex(
      $queryParams: IndexQueryParams
    ) {
      parentsIndex(
        queryParams: $queryParams
      ) {
        data {
          authUserEmail
          email
          id
          firstName
          lastName
          loggedInAt
          middleName
          phoneNumber
          receivedWelcomeEmailAt

          groups {
            id
            label
          }

          students {
            email
            id
            firstName
            lastName
            relationshipType {
              id
              label
            }
            studentId
          }

          # member_finances materialized view data
          ${canReadFinances ? `
            balanceDueInCents
            creditsTotalInCents
            feesAssignedInCents
            previousYearsBalanceDueInCents
          ` : ''}
          ${canReadPayments ? `
            paymentsTotalInCents
          ` : ''}
        }
        fullCount
      }
    }
  `;
};

export const useGetStudentsIndexQuery = () => {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  return gql`
    query StudentsIndex(
      $queryParams: IndexQueryParams
      $where: StudentIndexWhere
    ) {
      studentsIndex(
        queryParams: $queryParams
        where: $where
      ) {
        data {
          addressOne
          addressTwo
          adults {
            addressOne
            addressTwo
            authUserEmail
            city
            email
            firstName
            id
            lastName
            phoneNumber
            receivedWelcomeEmailAt
            relationshipType {
              id
              label
            }
            state {
              id
              label
            }
            zipcode
          }
          allergies
          authUserEmail
          city
          dateOfBirth
          email
          firstName
          gender {
            id
            label
          }
          grade
          groups {
            id
            label
          }
          id
          isEligible
          lastName
          loggedInAt
          middleName
          numberOfInventoryCheckouts
          numberOfUniformCheckouts
          otherGenderLabel
          phoneNumber
          previousCampus
          primaryRole {
            id
            label
          }
          primarySpokenLanguage {
            id
            label
          }
          privateLessonTeacher
          receivedWelcomeEmailAt
          secondaryRoles {
            id
            label
          }
          shirtSize
          state {
            id
            label
          }
          studentId
          successorOrganization {
            id
            label
          }
          zipcode

          dynamicField01
          dynamicField02
          dynamicField03
          dynamicField04
          dynamicField05
          dynamicField06
          dynamicField07
          dynamicField08
          dynamicField09
          dynamicField10
          dynamicField11
          dynamicField12
          dynamicField13
          dynamicField14
          dynamicField15
          dynamicField16
          dynamicField17
          dynamicField18
          dynamicField19
          dynamicField20
          dynamicField21
          dynamicField22
          dynamicField23
          dynamicField24
          dynamicField25
          dynamicField26
          dynamicField27
          dynamicField28
          dynamicField29
          dynamicField30
          dynamicField31
          dynamicField32
          dynamicField33
          dynamicField34
          dynamicField35
          dynamicField36
          dynamicField37
          dynamicField38
          dynamicField39
          dynamicField40
          dynamicField41
          dynamicField42
          dynamicField43
          dynamicField44
          dynamicField45
          dynamicField46
          dynamicField47
          dynamicField48
          dynamicField49
          dynamicField50

          # member_finances materialized view data
          ${canReadFinances ? `
            balanceDueInCents
            creditsTotalInCents
            feesAssignedInCents
            previousYearsBalanceDueInCents
          ` : ''}
          ${canReadPayments ? `
            paymentsTotalInCents
          ` : ''}
        }
        fullCount
      }
    }
  `;
};

const GET_MEMBER_WITH_RELATIVES = gql`
  query Member($id: ID!) {
    user(id: $id) {
      ...UserFragment
      relationships {
        id
        isConfirmed
        recipient {
          email
          id
          firstName
          lastName
          middleName
        }
        sender {
          email
          id
          firstName
          lastName
          middleName
        }
      }
    }
  },
  ${USER_FRAGMENT}
`;

const GET_STUDENT = gql`
  query Student($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  },
  ${USER_FRAGMENT}
`;

const GET_ADULT = gql`
  query Adult($id: ID!) {
    user(id: $id) {
      addressOne
      addressTwo
      allergies
      authUserEmail
      authUserId
      bouncedEmail {
        bouncedAt
        email
        id
        reason
        suppressedAt
      }
      city
      dateOfBirth
      email
      firstName
      gender {
        id
        label
      }
      groups {
        id
        label
      }
      id
      lastName
      loggedInAt
      middleName
      otherGenderLabel
      phoneNumber
      primarySpokenLanguage {
        id
        label
      }
      role {
        id
        label
      }
      secondaryEmail
      secondaryPhoneNumber
      shirtSize
      state {
        id
        label
      }
      stateId
      title
      zipcode
      userOrgData {
        id
        member {
          id
          loggedInAt
        }
        receivedWelcomeEmailAt
        roles {
          id
          isPrimary
          organizationRole {
            id
            label
          }
        }
      }
      permissions {
        ...UserPermissionsFragment
      }

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
  ${FRAGMENT_PERMISSION_ACTIONS}
  ${FRAGMENT_USER_PERMISSIONS}
`;

const GET_SIMPLE_USER = gql`
  query SimpleUser($userId: ID!) {
    user(id: $userId) {
      email
      firstName
      id
      lastName
      middleName
      role {
        id
        label
      }
    }
  }
`;

const GET_SUPPRESSED_EMAILS = gql`
  query SuppressedEmails(
    $queryParams: IndexQueryParams
  ) {
    suppressedEmails(
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        middleName
        reason
        suppressedAt
      }
      fullCount
    }
  }
`;

const GET_PENDING_MEMBERS_INDEX = gql`
  query PendingMembersIndex(
    $queryParams: IndexQueryParams
  ) {
    pendingMemberIndex(
      queryParams: $queryParams
    ) {
      data {
        email
        firstName
        id
        lastName
        middleName
        relationships {
          id
          recipient {
            email
            firstName
            id
            lastName
            middleName
          }
          sender {
            email
            firstName
            id
            lastName
            middleName
          }
        }
        role {
          canBeAdmin
          id
          label
        }
      }
      fullCount
    }
  }
`;

const GET_PENDING_MEMBERS_INDEX_ALL = gql`
  query PendingMembersIndexAll(
    $queryParams: IndexQueryParams
  ) {
    pendingMemberIndex(
      queryParams: $queryParams
    ) {
      data {
        id
      }
      fullCount
    }
  }
`;

const GET_PENDING_MEMBERS_COUNT = gql`
  query PendingMembersCount(
    $queryParams: IndexQueryParams
  ) {
    pendingMemberIndex(
      queryParams: $queryParams
    ) {
      fullCount
    }
  }
`;

const GET_PREVIOUS_MEMBERS = gql`
  query PreviousMembers(
    $where: AllMembersWhere
  ) {
    allMembers(where: $where) {
      email
      firstName
      grade
      id
      isEligible
      isActive
      lastName
      organizationMembershipId
      primaryRole {
        id
        label
      }
      roleLabel
      studentId
    }
  }
`;

export const MEMBER_LOOKUP = gql`
  query MemberLookup(
    $memberEmail: String!,
    $organizationCode: String,
  ) {
    memberLookup(
      input: {
        memberEmail:  $memberEmail
        organizationCode: $organizationCode
      }
    ) {
      createdAt
      loggedInAt
      loginEmail
    }
  }
`;

export const useGetSuppressedEmailsIndex = () =>
  usePaginatedListQuery<SuppressedEmailIndexResponse, GQL.ISuppressedEmailUserIndex>(
    {
      dataSelector: (data) => data.suppressedEmails.data,
      fullCountSelector: (data) => data.suppressedEmails.fullCount,
      query: GET_SUPPRESSED_EMAILS,
    },
  );

export const useGetUsersTypeAhead = (
  searchString: string,
  roleId?: string,
) => useQueryEnhanced<
  PeoplePickerIndexResponse
>(
  GET_PEOPLE_PICKER_INDEX,
  {
    fetchPolicy: 'network-only',
    skip: !searchString.trim(),
    variables: {
      queryParams: {
        limit: 8,
        page: 1,
        q: searchString.trim(),
      },
      ...roleId && ({
        where: {
          roleId: parseInt(roleId, 10),
        },
      }),
    },
  },
);

export const useGetNonDirectors = () =>
  useQueryEnhanced<{
    nonDirectors: GQL.IUserAll
  }>(GET_NON_DIRECTORS);

export const useGetSimpleUser = (userId?: string) => useQueryEnhanced<{
  user: SimpleUser;
}>(
  GET_SIMPLE_USER,
  {
    skip: !userId,
    variables: { userId },
  },
);

export const useGetMemberWithRelatives = (
  id?: string,
) => useQueryEnhanced<{
  user: GQL.IUser;
}>(
  GET_MEMBER_WITH_RELATIVES,
  {
    skip: !id,
    variables: { id },
  },
);

export const useGetFamilyMembersOnly = (memberId?: string) => {
  const {
    data: memberData,
    loading: isLoading,
  } = useGetMemberWithRelatives(memberId);

  const familyMembers = useMemo(() => memberData?.user.relationships?.map((relationship) => {
    if (relationship.recipient?.id === memberId) {
      return relationship.sender;
    }

    return relationship.recipient;
  }), [memberData, memberId]);

  return useMemo(() => ({
    familyMembers,
    isLoading,
  }), [familyMembers, isLoading]);
};

export const useGetStudent = (
  id?: string,
) => useQueryEnhanced<{
  user: StudentData;
}>(
  GET_STUDENT,
  {
    skip: !id,
    variables: { id },
  },
);

export const useGetAdult = (
  id?: string,
) => useQueryEnhanced<{
  user: GQL.IUser;
}>(
  GET_ADULT,
  {
    skip: !id,
    variables: { id },
  },
);

export const useGetMember = (
  id?: string | null,
) => useQueryEnhanced<{
  user: GQL.IUser;
}>(
  GET_USER,
  {
    skip: !id,
    variables: { id },
  },
);

export const useGetAllDirectors = () =>
  useQueryEnhanced<{
    allDirectors: GQL.IDirectorIndex[];
  }>(ALL_DIRECTORS);

export const useGetAllMembers = (options?: GetAllMembersOptions) =>
  useQueryEnhanced<{
    allMembers: GQL.IMemberIndex[];
  }>(ALL_MEMBERS, { skip: options?.skip });

export const useGetPendingMembersCount = (skip?: boolean) =>
  useQueryEnhanced<{
    pendingMemberIndex: GQL.ISimpleUserAll;
  }>(
    GET_PENDING_MEMBERS_COUNT,
    {
      skip,
    },
  );

export const useGetDirectorsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  skip?: boolean,
): [
    (
    ) => void,
    LazyQueryResult<DirectorsIndexResponse, DirectorsIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<DirectorsIndexResponse, DirectorsIndexVariables>({
    gqlQuery,
    options,
    skipQuery: skip,
    tableResource: 'directors',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDirectorsIndex = () => usePaginatedListQuery<
  DirectorsIndexResponse, GQL.IDirectorIndex>({
    dataSelector: (res) => res.directorsIndex.data,
    fullCountSelector: (res) => res.directorsIndex.fullCount,
    query: GET_DIRECTORS_INDEX,
  });

/* District Admin Directors start */
export const useGetDisrictAdminDirectorsQuery = (
  organizationId: DistrictAdminDirectorsVariables['organizationId'],
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<DistrictAdminDirectorsResponse, DistrictAdminDirectorsVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<DistrictAdminDirectorsResponse, DistrictAdminDirectorsVariables>({
    getAdditionalVariables: () => ({ organizationId }),
    gqlQuery,
    options,
    tableResource: 'districtOrganizationsDirectors',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictDirectorsIndex = (
  organizationId: string,
) => {
  const [query, values] = useGetDisrictAdminDirectorsQuery(
    organizationId,
    GET_DISTRICT_ORGANIZATIONS_DIRECTORS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};
/* District Admin Directors end */

/* District Admin "Parents with permissions" start */
export const useGetDisrictAdminParentsWithPermissionsQuery = (
  organizationId: DistrictAdminDirectorsVariables['organizationId'],
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      DistrictAdminParentsWithPermissionsResponse,
      DistrictAdminParentsWithPermissionsVariables
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    DistrictAdminParentsWithPermissionsResponse,
    DistrictAdminParentsWithPermissionsVariables
  >({
    getAdditionalVariables: () => ({ organizationId }),
    gqlQuery,
    options,
    tableResource: 'districtOrganizationsParentsWithPermissions',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictParentsWithPermissionsIndex = (
  organizationId: string,
) => {
  const [query, values] = useGetDisrictAdminParentsWithPermissionsQuery(
    organizationId,
    GET_DISTRICT_ORGANIZATIONS_PARENTS_WITH_PERMISSIONS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};
/* District Admin "Parents with permissions" end */

export const useGetInactiveIndex = () =>
  usePaginatedListQuery<InactiveIndexResponse, GQL.IInactiveUserIndex>(
    {
      dataSelector: (data) => data.inactiveUsersIndex.data,
      fullCountSelector: (data) => data.inactiveUsersIndex.fullCount,
      query: GET_INACTIVE_USERS_INDEX,
    },
  );

export const useGetParentsIndex = () => {
  const parentsIndexQuery = useGetParentsIndexQuery();

  return usePaginatedListQuery<
    ParentsIndexResponse, GQL.IParentIndex>({
      dataSelector: (res) => res.parentsIndex.data,
      fullCountSelector: (res) => res.parentsIndex.fullCount,
      query: parentsIndexQuery,
    });
};

export const useGetStudentsIndex = () => {
  const studentIndexQuery = useGetStudentsIndexQuery();

  return usePaginatedListQuery<
    StudentsIndexResponse, GQL.IStudentIndex>({
      dataSelector: (res) => res.studentsIndex.data,
      fullCountSelector: (res) => res.studentsIndex.fullCount,
      query: studentIndexQuery,
    });
};

export const useGetUserByDistrictAdmin = (
  variables: GQL.IUserByDistrictAdminOnQueryArguments,
) =>
  useQueryEnhanced<{
    userByDistrictAdmin: GQL.IUserByDistrictAdmin;
  }>(
    GET_USER_BY_DISTRICT_ADMIN,
    {
      skip: !variables.id || !variables.organizationId,
      variables,
    },
  );

// People Picker Index
export const useGetPeoplePickerIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  tableResource: PeoplePickerTableResource,
  skip?: boolean,
): [
    (
    ) => void,
    LazyQueryResult<
      PeoplePickerIndexResponse,
      GQL.IPeoplePickerIndexOnQueryArguments
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    PeoplePickerIndexResponse,
    GQL.IPeoplePickerIndexOnQueryArguments
  >({
    getAdditionalVariables: getAdditionalPeoplePickerVariables,
    gqlQuery,
    options,
    skipQuery: skip,
    tableResource,
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetPeoplePickerIndex = ({
  skip,
  tableResource,
}: {
  skip?: boolean,
  tableResource: PeoplePickerTableResource,
}) => {
  const [query, values] = useGetPeoplePickerIndexQuery(
    GET_PEOPLE_PICKER_INDEX,
    { exportReport: false },
    tableResource,
    skip,
  );

  useEffect(() => {
    query();
  }, [query, tableResource, skip]);

  return values;
};

export const useGetPeoplePickerIndexAll = ({
  tableResource,
}: {
  tableResource: PeoplePickerTableResource,
}) => {
  const searchString = useSelector(tableQueryParams(tableResource));
  const search = parseSearch(searchString);

  const queryParams = getIndexQueryParams(searchString);

  // We need to remove limit and page from
  //  the Redux params to get all ids
  delete queryParams.limit;
  delete queryParams.page;

  return useLazyQueryEnhanced<
    PeoplePickerIndexResponse,
    GQL.IPeoplePickerIndexOnQueryArguments
  >(
    GET_PEOPLE_PICKER_INDEX_ALL,
    {
      variables: {
        queryParams,
        ...getAdditionalPeoplePickerVariables(search),
      },
    },
  );
};

export const useGetPendingMembersIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      PendingMembersIndexResponse,
      GQL.IPendingMemberIndexOnQueryArguments
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    PendingMembersIndexResponse,
    GQL.IPendingMemberIndexOnQueryArguments
  >({
    gqlQuery,
    options,
    tableResource: 'pendingMembers',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetPendingMembersIndex = () =>
  usePaginatedListQuery<
    PendingMembersIndexResponse,
    GQL.ISimpleUser
  >({
    dataSelector: (res) => res.pendingMemberIndex.data,
    fullCountSelector: (res) => res.pendingMemberIndex.fullCount,
    query: GET_PENDING_MEMBERS_INDEX,
  });

export const useGetPendingMembersIndexAll = () => {
  const searchString = useSelector(tableQueryParams('pendingMembers'));

  const queryParams = getIndexQueryParams(searchString);

  // We need to remove limit and page from
  //  the Redux params to get all ids
  delete queryParams.limit;
  delete queryParams.page;

  return useLazyQueryEnhanced<
    PendingMembersIndexResponse,
    GQL.IPendingMemberIndexOnQueryArguments
  >(
    GET_PENDING_MEMBERS_INDEX_ALL,
  );
};

export const useGetUsersByIds = (
  ids: string[],
  skip: boolean,
) => useQueryEnhanced<{
  usersByIds: GQL.IUser[];
}>(
  GET_USERS_BY_IDS,
  {
    partialRefetch: true,
    skip,
    variables: { ids },
  },
);

export const usePreviousMembers = (schoolYearEnding: number) =>
  useQueryEnhanced<
    {
      allMembers: GQL.IMemberIndex[]
    },
    GQL.IAllMembersOnQueryArguments
  >(
    GET_PREVIOUS_MEMBERS,
    {
      variables: {
        where: {
          includeInactiveUsers: true,
          schoolYearEnding,
        },
      },
    },
  );

export const useMemberLookup = () =>
  useLazyQueryPromise<
    {
      memberLookup: GQL.IMemberLookup[]
    },
    GQL.IMemberLookupInput
  >(
    MEMBER_LOOKUP,
  );
