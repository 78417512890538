// Internal Dependencies
import { CustomSelect } from 'components/shared';

// Local Typings
interface Props {
  readOnly?: boolean;
}

export const repairPriorityOptions = [
  { id: 1, label: 'High', value: 1 },
  { id: 2, label: 'Medium', value: 2 },
  { id: 3, label: 'Low', value: 3 },
];

// Component Definition
const InventoryRepairPrioritySelect = ({
  readOnly,
}: Props): JSX.Element => {
  return (
    <CustomSelect
      inputProps={{ readOnly }}
      label="Priority"
      name="priority"
      options={repairPriorityOptions}
    />
  );
};

export default InventoryRepairPrioritySelect;
