// External Dependencies
import { FormTypes } from '@presto-assistant/api_types';
import { GridColDef } from '@mui/x-data-grid-pro';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';
import { mapEnum } from 'utils/lib/map_enum';

export const useColumns = (args: {
  actionsColumn: GridColDef<GQL.IFormIndexItem> | null;
}) => {
  return useMemo(() => {
    const columns: DataGridColDef<GQL.IFormIndexItem>[] = [
      {
        field: 'title',
        headerName: 'Title',
        width: 240,
      },
      {
        field: 'districtId',
        headerName: 'District Form',
        type: 'boolean',
      },
      {
        field: 'formTypeId',
        headerName: 'Form Type',
        renderCell: (params) => {
          // we have to keep this here for backwards compatibility
          // due to index db not having the form type id in stale data
          if (params.value) {
            return toTitleCase(FormTypes[params.value as FormTypes]);
          }

          return '';
        },
        type: 'singleSelect',
        valueOptions: () => {
          const formTypeOptions = mapEnum(FormTypes).map((option) => {
            return { ...option, value: option.id };
          });
          return formTypeOptions;
        },
        width: 160,
      },
      dateColumn({
        field: 'updatedAt',
        headerName: 'Updated',
      }),
      dateColumn({
        field: 'publishedAt',
        headerName: 'Published',
      }),
    ];

    if (args.actionsColumn) {
      columns.push(args.actionsColumn as DataGridColDef<GQL.IFormIndexItem>);
    }

    return columns;
  }, [args.actionsColumn]);
};
