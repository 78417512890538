// External Dependencies
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedCard, Page, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import AllFilesByDirectoryTable from './AllFilesByDirectoryTable';

// Component Definition
const FilesAllByDirectory = (): JSX.Element => {
  const { directory } = useParams<{ directory: string; }>();

  const directoriesParams = useSelector(tableQueryParams('fileDirectories'));

  return (
    <Page
      backButtonProps={{
        label: 'Files',
        path: `/${PATHS.FILES}${directoriesParams}`,
      }}
      isLoading={false}
    >
      <Subtitle>
        {directory!}
      </Subtitle>

      <EnhancedCard>
        <AllFilesByDirectoryTable directory={directory!} />
      </EnhancedCard>
    </Page>
  );
};

export default FilesAllByDirectory;
