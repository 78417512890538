// External Dependencies
import {
  CreateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import {
  FieldArray,
  Form,
  FormikErrors,
  FormikTouched,
} from 'formik';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  CustomInput,
  EnhancedAlert,
  FormRouteActions,
  ShowCard,
} from 'components/shared';

// Local Dependencies
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';
import ItemsFieldArray from 'pages/Finances/FinancialFundraiserCredits/shared/ItemsFieldArray';

// Local Typings
interface Props {
  errors: FormikErrors<CreateFinancialFundraiserCreditRequest>;
  financialAccountIdFormikValue: string;
  isIndividualizedFormikValue: boolean;
  isSubmitting: boolean;
  itemsFormikValue: CreateFinancialFundraiserCreditRequest['items'];
  onPressCancelOrBackButton: () => void;
  schoolYearEndingFormikValue: number;
  touched: FormikTouched<CreateFinancialFundraiserCreditRequest>;
}

// Component Definition
const UpdateFinancialFundraiserCreditForm = ({
  errors,
  financialAccountIdFormikValue,
  isIndividualizedFormikValue,
  isSubmitting,
  itemsFormikValue,
  onPressCancelOrBackButton,
  schoolYearEndingFormikValue,
  touched,
}: Props): JSX.Element => {
  return (
    <Form>
      <Box mb={2}>
        <ShowCard
          icon={CurrencyExchangeIcon}
          title="Edit Fundraiser Credit"
        >
          <CustomInput
            label="Fundraiser Name"
            name="label"
          />

          <Collapse in={!isIndividualizedFormikValue}>
            <CustomInput
              helperText="The amount reduced for each student assigned the selected fees"
              isCurrency
              label="Amount"
              name="defaultAmountInCents"
            />
          </Collapse>

          <Box marginY={2}>
            <Typography>
              Financial Items
            </Typography>

            <FormHelperText>
              Fundraiser credits are applied to members&apos;
              {' '}
              fees in the order of priority selected.
            </FormHelperText>

            {(errors?.items?.length ?? 0) > 0 && touched.items && (
              <EnhancedAlert
                severity="error"
                sx={{ marginY: 1 }}
              >
                {errors.items}
              </EnhancedAlert>
            )}

            <FinancialAccountSelect includeAllOption />

            <FieldArray name="items">
              {(arrayHelpers) => (
                <ItemsFieldArray
                  arrayHelpers={arrayHelpers}
                  financialAccountIdFormikValue={financialAccountIdFormikValue}
                  itemsFormikValue={itemsFormikValue}
                  schoolYearEndingFormikValue={schoolYearEndingFormikValue}
                />
              )}
            </FieldArray>
          </Box>
        </ShowCard>
      </Box>

      <FormRouteActions
        context="Fundraiser Credit"
        isEditing
        isFormTouched
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={onPressCancelOrBackButton}
      />
    </Form>
  );
};

export default UpdateFinancialFundraiserCreditForm;
