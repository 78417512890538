// External Dependencies
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import AdminIcon from 'mdi-material-ui/ShieldAccount';
import OrgIcon from '@mui/icons-material/MusicNote';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedMenuItem } from 'components/shared';
import {
  currentDistrictAdminOrgId as currentDistrictAdminOrgIdSelector,
} from 'state/self/selectors';
import { dfaMemberIdPlaceholder } from 'utils/api/auth';
import { getFullName } from 'utils';
import { toggleOrganization } from 'utils/api';
import { useParseToken } from 'utils/cookies';
import SsoSignInDialog from 'components/shared/SsoSignInDialog';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  closeParent: () => void;
  currentOrgId?: string | null;
  onClickJoinOrg: () => void;
  shouldShowMemberProfileInfo: boolean;
  userOrgData: GQL.IUserOrganization[];
}

// Component Definition
const OrgSubMenu = ({
  closeParent,
  currentOrgId,
  onClickJoinOrg,
  shouldShowMemberProfileInfo,
  userOrgData,
}: Props): JSX.Element | null => {
  const [switchToMemberId, setSwitchToMemberId] = useState<string | null>(null);
  const [switchToMemberSsoTypeId, setSwitchToMemberSsoTypeId] = useState<number | null>(null);

  const dispatch = useDispatch();

  const { self } = useSelfQuery();

  const currentDistrictAdminOrgId = useSelector(currentDistrictAdminOrgIdSelector);

  const token = useParseToken();

  const ssoTypeIdForToken = token?.districtSsoTypeId;
  const ssoTenantIdForToken = token?.ssoTenantId;

  const handleCloseSsoDialog = useCallback(() => {
    setSwitchToMemberId(null);
    setSwitchToMemberSsoTypeId(null);
  }, []);

  if (!self) {
    return null;
  }

  const { districtMemberships } = self;

  const hasDistrictMembership = districtMemberships.length > 0;

  const handleClickOrg = ({
    isAdmin,
    memberId,
    ssoTenantId,
    ssoTypeId,
  }: {
    isAdmin: boolean;
    memberId: string | null;
    ssoTenantId: string | null;
    ssoTypeId: number | null;
  }) => {
    const requiresSso = Boolean(ssoTypeId
      && ssoTenantId
      && ssoTypeId !== ssoTypeIdForToken
      && ssoTenantId !== ssoTenantIdForToken
      && isAdmin);

    if (!requiresSso && memberId !== self.id) {
      toggleOrganization(memberId, dispatch);
      closeParent();
    }

    if (requiresSso && memberId !== self.id) {
      setSwitchToMemberId(memberId ?? dfaMemberIdPlaceholder);
      setSwitchToMemberSsoTypeId(ssoTypeId);
    }

    if (memberId === self.id) {
      closeParent();
    }
  };

  return (
    <>
      {hasDistrictMembership && districtMemberships.map((districtMembership) => {
        const ssoTypeId = districtMembership?.district?.districtSsoTypeId;
        const ssoTenantId = districtMembership?.district?.ssoTenantId;

        return (
          <EnhancedMenuItem
            icon={AdminIcon}
            isActive={!currentOrgId
              && currentDistrictAdminOrgId === districtMembership?.district?.id}
            isOrgName
            key={districtMembership.id}
            onClick={() => handleClickOrg({
              isAdmin: true,
              memberId: null,
              ssoTenantId: ssoTenantId ?? null,
              ssoTypeId: ssoTypeId ?? null,
            })}
            secondaryText={districtMembership?.district?.label && (
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {districtMembership?.district?.label}
              </Typography>
            )}
            text="Admin"
          />
        );
      })}
      {userOrgData.map((userOrg) => {
        const {
          admin,
          id,
          member,
          organization: {
            district: organizationDistrict,
            entityType: {
              label: entityTypeLabel,
            },
            id: organizationId,
            label: orgName,
            organizationType: {
              label: organizationTypeLabel,
            },
          },
        } = userOrg;

        const ssoTypeId = organizationDistrict?.districtSsoTypeId;
        const ssoTenantId = organizationDistrict?.ssoTenantId;

        const roleLabel = admin ? 'Director' : member?.role?.label ?? '';

        const typeInfo = (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {entityTypeLabel} {organizationTypeLabel}{roleLabel ? ` (${roleLabel})` : ''}
          </Typography>
        );

        const primaryText = member?.loggedInAt ? orgName : `${orgName} (NEW)`;

        return (
          <EnhancedMenuItem
            icon={OrgIcon}
            isActive={member ? member.id === self.id : currentOrgId === organizationId}
            isOrgName
            key={id}
            onClick={() => handleClickOrg({
              isAdmin: admin ?? false,
              memberId: member?.id ?? null,
              ssoTenantId: ssoTenantId ?? null,
              ssoTypeId: ssoTypeId ?? null,
            })}
            secondaryText={shouldShowMemberProfileInfo && (member || self)
              ? (
                <>
                  {typeInfo}
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {getFullName(member ?? self!)}
                  </Typography>
                </>
              )
              : typeInfo}
            text={primaryText}
          />
        );
      })}

      <EnhancedMenuItem
        icon={AddIcon}
        key="join-new"
        onClick={onClickJoinOrg}
        text="Join Organization"
      />

      <SsoSignInDialog
        isOpen={Boolean(switchToMemberId)}
        onClose={handleCloseSsoDialog}
        ssoTypeId={switchToMemberSsoTypeId ?? undefined}
        switchToMemberId={switchToMemberId ?? undefined}
      />
    </>
  );
};

export default OrgSubMenu;
