// External Dependencies
import { useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import DialogContentText from '@mui/material/DialogContentText';
import SwapVerticalIcon from '@mui/icons-material/SwapVert';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';
import { useRemoveAuthUser } from 'gql/mutations';

const StyledDialog = styled(ConfirmationDialog)(({ theme }) => ({
  '.bottom-text': {
    marginTop: theme.spacing(1.5),
  },
  '.email-card': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '.email-card-content': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  '.error-alert': {
    marginTop: theme.spacing(1.5),
  },
  '.swap-icon': {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  authUserEmail: string;
  isOpen: boolean;
  memberEmail: string;
  memberId: string;
  onClose: () => void;
}

// Component Definition
const RemoveAuthMemberDialog = ({
  authUserEmail,
  isOpen,
  memberEmail,
  memberId,
  onClose,
}: Props): JSX.Element => {
  const [
    handleRemoveAuthUser,
    {
      error,
      loading,
    },
  ] = useRemoveAuthUser({
    onCompleted: () => {
      onClose();
    },
  });

  const handleClickRemoveButton = useCallback((): void => {
    const variables: GQL.IRemoveAuthUserOnMutationArguments = {
      input: {
        memberId,
      },
    };

    handleRemoveAuthUser({
      variables,
    });
  }, [handleRemoveAuthUser, memberId]);

  return (
    <StyledDialog
      canBeUndone
      confirmButtonAction={handleClickRemoveButton}
      confirmButtonText="Yes, Unlink"
      declineButtonAction={onClose}
      description={(
        <>
          <DialogContentText paragraph>
            Separate the Login Email from the member, allowing the member to
            connect their account to a different Login Email.
          </DialogContentText>

          <DialogContentText paragraph>
            This action might be used when a parent has claimed their child&apos;s member.
          </DialogContentText>

          <EnhancedCard
            className="email-card"
            sx={{ marginBottom: 2 }}
          >
            <EnhancedCardContent className="email-card-content">
              <DialogContentText>
                {authUserEmail}
              </DialogContentText>

              <div>
                <SwapVerticalIcon
                  className="swap-icon"
                  fontSize="large"
                />
              </div>

              <DialogContentText>
                {memberEmail}
              </DialogContentText>
            </EnhancedCardContent>
          </EnhancedCard>

          <DialogContentText className="bottom-text">
            Are you sure you want to unlink the Login Email from this Member?
          </DialogContentText>

          <Collapse in={Boolean(error)}>
            <EnhancedAlert
              className="error-alert"
              severity="error"
              title="Error"
            >
              {error?.message}
            </EnhancedAlert>
          </Collapse>
        </>
      )}
      handleClose={onClose}
      isSubmitting={loading}
      maxWidth="sm"
      open={isOpen}
      title="Unlink Account Email?"
      useCustomText
    />
  );
};

export default RemoveAuthMemberDialog;
