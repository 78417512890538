// External Dependencies
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  CreateInventoryRepairInput,
  UpdateInventoryRepairInput,
} from '@presto-assistant/api_types/schemas/inventoryRepair';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictQueryCache } from 'utils/lib/graphql';
import { useTanstackMutation } from 'utils/api/index';

// Hook Definitions
export const useGetInventoryItemRepairList = (inventoryItemId: string) => {
  return useQuery({
    enabled: Boolean(inventoryItemId),
    queryFn: () => {
      return apiClient.v1.inventoryRepair.list({
        params: { inventoryItemId },
      });
    },
    queryKey: [inventoryItemId, 'inventoryItemRepairList'],
  });
};

export const useCreateInventoryRepair = (inventoryItemId: string) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: CreateInventoryRepairInput) => {
    return apiClient.v1.inventoryRepair.createOne({
      body: payload,
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictQueryCache(queryClient, [inventoryItemId, 'inventoryItemRepairList']);
    },
  });
};

export const useUpdateInventoryRepair = ({
  inventoryItemId,
  inventoryRepairId,
}: {
  inventoryItemId: string;
  inventoryRepairId: string;
}) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: UpdateInventoryRepairInput) => {
    return apiClient.v1.inventoryRepair.updateOne({
      body: payload,
      params: { id: inventoryRepairId },
    });
  }, [inventoryRepairId]);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictQueryCache(queryClient, [inventoryRepairId, 'inventoryItemRepairList']);
      evictQueryCache(queryClient, [inventoryItemId, 'inventoryItemRepairList']);
    },
  });
};
