// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';

// Internal Dependencies
import { hasFeatureFlag } from 'state/self/selectors';
import { showClassLinkSso } from 'utils/constants';
import { useGetDistrict } from 'gql/queries';
import IntegrationsCard from 'components/shared/IntegrationsCard';

// Local Dependencies
import ClassLinkSsoSetting from './ClassLinkSsoSetting';
import OnlinePaymentsIntegrationSetting from './OnlinePaymentsIntegrationSetting';

// Component Definition
const DistrictIntegrationSettings = (): JSX.Element | null => {
  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  const { data: districtData } = useGetDistrict();

  const districtName = districtData?.district.label;

  if (!hasOnlinePaymentsFeatureFlag) {
    return null;
  }

  return (
    <IntegrationsCard>
      <List>
        <OnlinePaymentsIntegrationSetting />

        {showClassLinkSso(districtName) && <ClassLinkSsoSetting />}
      </List>
    </IntegrationsCard>
  );
};

export default DistrictIntegrationSettings;
