// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import EditAdultPermissions from '../../shared/EditAdultPermissions';

// Componeant Definition
const EditParentPermissions = (): JSX.Element | null => {
  const { id } = useParamsWithId();

  if (!id) {
    return null;
  }

  return (
    <EditAdultPermissions
      adultUserId={id}
      context="Parent"
      pathToShowPage={`/${PATHS.PARENTS}/${id}`}
    />
  );
};

export default EditParentPermissions;
