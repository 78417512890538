// External Dependencies
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

// Local Typings
interface Props {
  height?: number;
  width?: number;
}

// Component Definition
const ClassLinkLogo = ({
  height,
  width,
}: Props) => {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const color = isDarkMode ? theme.palette.text.primary : '#0a4d7f';

  const svgStyle = useMemo(() => ({ height, width }), [height, width]);
  const fillStyle = useMemo(() => ({ fill: color }), [color]);

  return (
    <svg
      id="Layer_2"
      style={svgStyle}
      viewBox="0 0 300 49.38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1-2">
        <path
          d="m93.21,19.67c0-6.34-1.29-9.08-5.21-9.08-4.43,0-5.47,4.43-5.47,16.76,0,13.15,1.8,15.52,5.62,15.52,3.15,0,5.47-1.65,5.47-11.04h8.77c0,9.39-2.32,17.48-13.82,17.48-13.15,0-14.8-9.39-14.8-22.59s1.65-22.59,14.8-22.59,13.46,9.18,13.46,15.52h-8.82Z"
          style={fillStyle}
        />
        <path
          d="m106.15,4.98h8.41v43.42h-8.41V4.98Z"
          style={fillStyle}
        />
        <path
          d="m119.2,25.76v-.77c0-7.53,5.16-10.11,11.5-10.11,10,0,11.86,4.13,11.86,10.06v17.22c0,3.04.21,4.43,1.03,6.34h-8.04c-.57-1.03-.77-2.32-1.03-3.4h-.1c-1.96,3.4-4.33,4.28-8.35,4.28-5.83,0-7.89-5-7.89-9.39,0-6.14,2.48-8.66,8.25-10.21l4.74-1.29c2.48-.67,3.35-1.65,3.35-3.82,0-2.48-1.08-4.02-3.87-4.02-2.58,0-3.87,1.7-3.87,4.28v.93l-7.58-.1h0Zm15.32,6.29c-1.13.88-2.58,1.39-3.61,1.65-3.4.72-4.74,2.37-4.74,5.31,0,2.48.93,4.54,3.15,4.54s5.16-1.44,5.16-5.05l.05-6.45h0Z"
          style={fillStyle}
        />
        <path
          d="m153.44,37.72v1.08c0,2.84,1.55,4.74,4.38,4.74,2.58,0,4.28-1.29,4.28-3.87,0-2.11-1.39-3.09-3.04-3.71l-5.93-2.11c-4.64-1.65-7.01-4.49-7.01-9.13,0-5.41,3.4-9.85,12.07-9.85,7.63,0,11.19,3.4,11.19,9.28v1.34h-7.53c0-3.3-.98-4.95-3.71-4.95-2.11,0-3.97,1.24-3.97,3.51,0,1.6.83,2.94,3.66,3.82l5.05,1.7c5.31,1.75,7.22,4.43,7.22,9.39,0,6.86-5.11,10.26-12.17,10.26-9.44,0-12.07-4.13-12.07-10.42v-1.13l7.58.05h0Z"
          style={fillStyle}
        />
        <path
          d="m180.46,37.72v1.08c0,2.84,1.55,4.74,4.38,4.74,2.58,0,4.28-1.29,4.28-3.87,0-2.11-1.39-3.09-3.04-3.71l-5.93-2.11c-4.64-1.65-7.01-4.49-7.01-9.13,0-5.41,3.4-9.85,12.07-9.85,7.63,0,11.19,3.4,11.19,9.28v1.34h-7.53c0-3.3-.98-4.95-3.71-4.95-2.11,0-3.97,1.24-3.97,3.51,0,1.6.83,2.94,3.66,3.82l5.05,1.7c5.31,1.75,7.22,4.43,7.22,9.39,0,6.86-5.11,10.26-12.17,10.26-9.44,0-12.07-4.13-12.07-10.42v-1.13l7.58.05h0Z"
          style={fillStyle}
        />
        <path
          d="m201.61,48.45V5.03h8.77v36.25h15.52v7.17s-24.29,0-24.29,0Z"
          style={fillStyle}
        />
        <path
          d="m229.81,4.51h8.41v7.17h-8.41v-7.17Zm0,11.19h8.41v32.75h-8.41V15.7Z"
          style={fillStyle}
        />
        <path
          d="m242.91,15.7h8.04v3.82h.1c1.75-3.2,4.8-4.69,8.35-4.69,5.26,0,8.35,2.84,8.35,9.28v24.29h-8.41v-22.54c0-3.3-1.08-4.54-3.61-4.54-2.84,0-4.43,1.75-4.43,5.41v21.71h-8.41V15.7h0Z"
          style={fillStyle}
        />
        <path
          d="m280.82,4.98v22.95h.1l8.46-12.22h9.23l-9.44,12.89,10.83,19.85h-9.23l-7.01-13.46-2.99,3.87v9.54h-8.41V4.98h8.46Z"
          style={fillStyle}
        />
        <path
          d="m20.08,29.16h0c-2.68,3.04,0,4.54,0,4.54.1.05.21.1.31.1-.41-1.65-.46-3.2-.31-4.64Z"
          style={fillStyle}
        />
        <path
          d="m37.61,33.13c-2.11,1.44-4.54,6.19-4.54,6.19h-1.29c.83-1.8,1.34-3.3,1.65-4.54-.31.1-.52.15-.52.15-2.53.88-5.11,1.29-7.37,1.34,2.42,3.61,6.81,4.23,6.81,4.23,6.14.83,10.83-2.53,13.3-5-5.16-2.84-8.04-2.37-8.04-2.37Z"
          style={fillStyle}
        />
        <path
          d="m57.52,19.88s4.07-10.06-5.21-16.97c0,0-14.85-9.8-23.15,6.55,0,0-5.93-5.93-13.25-1.34-6.29,3.92-5.21,9.28-4.64,11.91,0,0-11.81,3.46-11.24,16.09.41,9.33,9.49,12.17,13,12.89.77.15,1.55.26,2.32.26h38.83c.77,0,1.5-.05,2.27-.21,3.56-.72,12.74-3.56,12.58-13.51-.21-12.33-11.5-15.68-11.5-15.68Zm-25.48,22.59c-7.84-.1-10.67-5.98-10.67-5.98-.15-.31-.31-.62-.41-.93-2.17-.83-3.46-2.32-3.15-4.59.31-2.27,2.89-4.54,2.89-4.54h0c1.5-4.64,4.85-7.79,4.85-7.79,0,0,2.84-2.99,5.88-4.54l5.36.83c-12.12,4.95-12.74,15.16-12.74,15.16-.1,1.55.1,2.84.46,3.97.93-.05,1.55-.15,1.55-.15,2.99-.46,5.57-1.29,7.79-2.22-.05-2.01-.98-2.53-.98-2.53,0,0-1.08-1.24-5.62-2.01l.41-.62s2.22.52,4.74,0c2.73-.52,5.26-2.42,8.1-6.29l1.34-.05c-2.73,3.66-2.94,6.39-2.48,8.2,2.73-2.06,4.02-3.82,4.02-3.82,4.95-6.91-1.91-5.83-1.91-5.83l2.06-2.42c10.47,2.27.88,11.76.88,11.76-1.13,1.13-2.37,2.11-3.56,2.99.15.21,2.01,2.06,5.47,3.71.93-.98,1.39-1.7,1.39-1.7l1.39.26c-8.2,9.9-17.07,9.13-17.07,9.13Zm3.71-19.65c-.05,1.13-.98,2.06-2.17,2.11-1.29,0-2.32-1.03-2.27-2.32.05-1.13.98-2.06,2.17-2.11,1.29,0,2.32,1.03,2.27,2.32Z"
          style={fillStyle}
        />
      </g>
    </svg>
  );
};

export default ClassLinkLogo;
