// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { UserRoles } from '@presto-assistant/api_types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';
import Grid from '@mui/material/Grid';

// Internal Dependencies
import {
  DynamicFormFields,
  EnhancedContainer,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import {
  hasPermission,
} from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetSelf } from 'gql/queries';
import ContactInfoFields from 'pages/People/shared/ContactInfoFields';
import ProfileInfoFields from 'pages/People/shared/ProfileInfoFields';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import PeopleDangerZone from './PeopleDangerZone';

// Local Typings
interface Props {
  initialValues?: Partial<GQL.ICreateUserInput>;
  isDfa?: boolean;
  isDirector?: boolean;
  onSubmit?: (values: GQL.ICreateUserInput) => Promise<void>;
  returnToOnRemoveUser?: string;
  user?: GQL.IUser;
  validationSchema?: any;
}

// Component Definition
const AdultForm: FC<Props> = ({
  initialValues = {},
  isDfa = false,
  isDirector = false,
  onSubmit,
  returnToOnRemoveUser,
  user,
  validationSchema,
}) => {
  const navigate = useNavigate();

  const userId = user?.id;
  const userOrganizationId = user?.userOrgData?.id;

  const { data: selfData } = useGetSelf();

  const isSelf = selfData?.self?.id === userId;

  const defaultInitialValues = {
    addressOne: '',
    addressTwo: '',
    admin: isDirector,
    allergies: '',
    city: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    genderId: '',
    lastName: '',
    phoneNumber: '',
    primarySpokenLanguageId: 1,
    roleId: UserRoles.Adult,
    secondaryEmail: '',
    secondaryPhoneNumber: '',
    shirtSize: '',
    stateId: '',
    title: '',
    zipcode: '',
  };

  const canUseDynamicFields = useCanUseDynamicFields() && !isDfa;
  const directorsParams = useSelector(tableQueryParams('directors'));
  const parentsParams = useSelector(tableQueryParams('parents'));

  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const directorsTablePath = `/${PATHS.DIRECTORS}${directorsParams}`;
  const parentsTablePath = `/${PATHS.PARENTS}${parentsParams}`;

  const isEditing = Boolean(user) || isDfa;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(isDirector ? directorsTablePath : parentsTablePath);
  }, [navigate, isDirector, directorsTablePath, parentsTablePath]);

  const handleFormikSubmit = async (
    values: GQL.ICreateUserInput,
    formikProps: FormikHelpers<GQL.ICreateUserInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  const formRouteContext = useMemo(() => {
    if (isDfa) {
      return 'District Admin';
    }

    if (isDirector) {
      return 'Director';
    }

    return 'Parent';
  }, [isDirector, isDfa]);

  return (
    <>
      <Formik<GQL.ICreateUserInput>
        initialValues={{
          ...((canUseDynamicFields && canEditUsers) ? applyDynamicFields(undefined) : {}),
          ...defaultInitialValues,
          ...initialValues,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <EnhancedContainer maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      icon={AccountCircleIcon}
                      title="Profile Info"
                    >
                      <ProfileInfoFields
                        isDfa={isDfa}
                        isDirector={isDirector}
                      />
                    </ShowCard>
                  </Box>
                </EnhancedContainer>

                <EnhancedContainer maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      icon={CellphoneWirelessIcon}
                      title="Contact Info"
                    >
                      <ContactInfoFields />
                    </ShowCard>
                  </Box>

                  {canEditUsers && !isDfa && (
                    <DynamicFormFields
                      isAdmin={false}
                      item={user ?? undefined}
                      organizationTypeId={undefined}
                      showCardProps={{
                        canEdit: isEditing,
                        readOnly: false,
                      }}
                      tableRef="members"
                    />
                  )}
                </EnhancedContainer>
              </Grid>

              <FormActions
                context={formRouteContext}
                isEditing={isEditing}
                isFormTouched={isFormTouched}
                isSubmitting={isSubmitting}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />
            </Form>
          );
        }}
      </Formik>

      {userOrganizationId
        && returnToOnRemoveUser
        && !isSelf
        && (
          <EnhancedContainer>
            <PeopleDangerZone
              authUserEmail={user.authUserEmail}
              isDirector={isDirector}
              memberEmail={user.email}
              memberId={userId}
              memberRoleId={!isDirector ? UserRoles.Adult : undefined}
              organizationMembershipId={userOrganizationId}
              returnToOnSuccess={returnToOnRemoveUser}
            />
          </EnhancedContainer>
        )}
    </>
  );
};

export default AdultForm;
