// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetInventoryItem, useGetOrganization } from 'gql/queries';
import { useHasPermission } from 'state/self/selectors';
import { useParamsWithId } from 'hooks/useParamsWithId';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import InventoryCheckoutCard from '../shared/InventoryCheckoutCard';
import InventoryForm from '../shared/InventoryForm';
import InventoryRepairCard from '../shared/InventoryRepairCard';

// Component Definition
const InventoryShow = (): JSX.Element => {
  const { id } = useParamsWithId();

  const { data: organizationData } = useGetOrganization();

  const organizationId = organizationData?.organization?.id;

  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const inventoryOrganizationId = data?.inventoryItem?.organization?.id;

  const ownsInventoryItem = organizationId === inventoryOrganizationId;

  const canReadInventoryRepairs = useHasPermission('inventoryRepairs', 'read');
  const canEditInventoryBasicInfo = useHasPermission('inventory', 'edit')
    && ownsInventoryItem;
  const canEditInventoryStatusInfo = useHasPermission('inventoryStatusInfo', 'edit')
    && ownsInventoryItem;

  const canEditInventory = canEditInventoryBasicInfo || canEditInventoryStatusInfo;

  const inventoryParams = useSelector(tableQueryParams('inventoryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.INVENTORY}${inventoryParams}`,
      }}
      editButtonPath={canEditInventory
        ? `/${PATHS.INVENTORY}/${id}/edit`
        : undefined}
      error={error}
      isLoading={!data || loading}
    >
      {data && (
        <>
          <InventoryForm
            canUseDynamicFields={canUseDynamicFields}
            inventoryItem={data.inventoryItem}
            readOnly
          />

          <InventoryCheckoutCard inventoryItemData={data.inventoryItem} />

          {id && canReadInventoryRepairs && <InventoryRepairCard inventoryItemId={id} />}

          {data?.inventoryItem && (
            <History
              id={data.inventoryItem.id}
              resource="inventoryItems"
            />
          )}
        </>
      )}
    </Page>
  );
};

export default InventoryShow;
