// External Dependencies
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

interface Props {
  denominator: number;
  displayCaptionAsCurrency?: boolean;
  hideCaption?: boolean;
  hideLabel?: boolean;
  label: string;
  numerator: number;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.StyledLinearProgress': {
    backgroundColor: `${theme.palette.prestoPrimary}44`,
    borderRadius: 6,
    height: 12,
  },
  '.strong': {
    fontWeight: 'bold',
  },
}));

export const getPercentage = ({
  denominator,
  numerator,
}: {
  denominator: number;
  numerator: number;
}): number => {
  if (numerator === 0) {
    return 0;
  }

  const percent = ((numerator / denominator) * 100);

  return Math.max(Math.floor(percent), 1);
};

const PercentageProgressBar = ({
  denominator,
  displayCaptionAsCurrency,
  hideCaption,
  hideLabel,
  label,
  numerator,
}: Props): JSX.Element => {
  const percentage = useMemo(() => {
    return getPercentage({ denominator, numerator });
  }, [denominator, numerator]);

  const caption = useMemo(() => {
    if (hideCaption) {
      return '';
    }

    if (displayCaptionAsCurrency) {
      const renderedDenominator = displayPriceStringFromDollarAmount(
        convertCentsToDollars(numerator),
      );
      const renderedNumerator = displayPriceStringFromDollarAmount(
        convertCentsToDollars(denominator),
      );

      return `${renderedDenominator} / ${renderedNumerator}`;
    }

    return `${numerator} / ${denominator}`;
  }, [denominator, displayCaptionAsCurrency, hideCaption, numerator]);

  return (
    <StyledRoot
      paddingY={1}
    >
      {!hideLabel && (
        <Box
          alignItems="flex-end"
          display="flex"
          justifyContent="space-between"
          marginBottom={1}
        >
          <Typography>
            {label}
          </Typography>

          <Typography className="strong">
            {percentage}%
          </Typography>
        </Box>
      )}

      <LinearProgress
        aria-label={`${label}: ${percentage} percent.`}
        className="StyledLinearProgress"
        value={percentage}
        variant="determinate"
      />

      {caption && (
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={0.5}
        >
          <Typography variant="caption">
            {caption}
          </Typography>
        </Box>
      )}
    </StyledRoot>
  );
};

export default PercentageProgressBar;
