// External Dependencies
import { Form, Formik, FormikHelpers } from 'formik';
import { createInventoryRepairSchema } from '@presto-assistant/api_types/schemas/inventoryRepair';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import type { InventoryRepairIndexResponseItem } from '@presto-assistant/api_types/api/v1/inventoryRepair';

// Internal Dependencies
import {
  CustomInput,
  DatePickerField,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  FormActions,
  InventorySimpleDataCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  SimpleInventoryItem,
} from 'gql/queries';
import {
  isDistrictAdmin as selectIsDistrictAdmin,
} from 'state/self/selectors';

// Local Dependencies
import InventoryRepairPrioritySelect from './InventoryRepairPrioritySelect';
import type { EditInventoryRepairFormValues } from '../Edit/types.d';
import type { NewInventoryRepairFormValues } from '../New/types.d';

// Local Typings
type RepairFormValues = NewInventoryRepairFormValues | EditInventoryRepairFormValues;

interface Props {
  intialValues: RepairFormValues;
  inventoryItem: SimpleInventoryItem;
  inventoryRepair?: InventoryRepairIndexResponseItem;
  onSubmit?: (values: RepairFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  margin: theme.spacing(1, 0, 0.5),
  whiteSpace: 'pre',
})) as typeof Typography;

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.inventory-item-card': {
    paddingBottom: 0,
  },
  '.tip-alert': {
    marginBottom: theme.spacing(1.5),
  },
}));

// Component Definition
const InventoryRepairForm = ({
  intialValues,
  inventoryItem,
  inventoryRepair,
  onSubmit,
  readOnly,
  title,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const isDistrictAdmin = useSelector(selectIsDistrictAdmin);

  const inventoryShowPageBasePath = `/${PATHS.INVENTORY}/${inventoryItem.id}`;
  const inventoryShowPagePath = isDistrictAdmin
    ? `/${PATHS.DISTRICT_ADMIN}${inventoryShowPageBasePath}`
    : inventoryShowPageBasePath;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(inventoryShowPagePath);
  }, [inventoryShowPagePath, navigate]);

  const handleFormikSubmit = useCallback(async (
    values: RepairFormValues,
    formikProps: FormikHelpers<RepairFormValues>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  }, [onSubmit, readOnly]);

  return (
    <EnhancedContainer>
      <Subtitle>
        {title}
      </Subtitle>

      <StyledEnhancedCard>
        <EnhancedCardContent className="inventory-item-card">
          <InventorySimpleDataCard
            brand={inventoryItem.brand}
            label={inventoryItem.label}
            model={inventoryItem.model}
            serialNumber={inventoryItem.serialNumber}
          />
        </EnhancedCardContent>

        <Formik<RepairFormValues>
          initialValues={intialValues}
          onSubmit={handleFormikSubmit}
          validationSchema={createInventoryRepairSchema}
        >
          {({
            handleSubmit,
            isSubmitting,
            touched,
          }) => {
            const isFormTouched = Object.keys(touched).length > 0;

            return (
              <Form onSubmit={handleSubmit}>
                <EnhancedCardContent>
                  <EnhancedAlert
                    className="tip-alert"
                    isTip
                    title="Service Vendor"
                  >
                    Add the Service Vendor in the comments,
                    along with any other relevant information.
                    A future update to this form will add a separate Service Vendor field.
                  </EnhancedAlert>

                  <CustomInput
                    InputProps={{
                      multiline: true,
                    }}
                    label="Comments"
                    name="comments"
                  />

                  <DatePickerField
                    ariaLabel="Change sent date"
                    disableFuture={false}
                    label="Sent Date"
                    name="sentDate"
                    readOnly={readOnly}
                    required
                  />

                  <InventoryRepairPrioritySelect readOnly={readOnly} />

                  <StyledTypography variant="h6">
                    Return Data
                  </StyledTypography>

                  <DatePickerField
                    ariaLabel="Change estimated return date"
                    disableFuture={false}
                    label="Estimated Return Date"
                    name="estimatedReturnDate"
                    readOnly={readOnly}
                  />

                  <DatePickerField
                    ariaLabel="Actual return date"
                    disableFuture={false}
                    label="Actual Return Date"
                    name="actualReturnDate"
                    readOnly={readOnly}
                  />

                  <EnhancedAlert>
                    The repair will be considered &quot;open&quot;
                    until the Actual Return Date is provided, which
                    can be added later.
                  </EnhancedAlert>

                  <StyledTypography variant="h6">
                    Cost Data
                  </StyledTypography>

                  <CustomInput
                    isCurrency
                    label="Estimated Cost"
                    name="estimatedCostInCents"
                    type="number"
                  />
                  <CustomInput
                    isCurrency
                    label="Actual Cost"
                    name="actualCostInCents"
                    type="number"
                  />
                </EnhancedCardContent>

                {!readOnly && (
                  <FormActions
                    context="Inventory Repair"
                    isEditing={!!inventoryRepair}
                    isFormTouched={isFormTouched}
                    isSubmitting={isSubmitting}
                    onPressCancelOrBackButton={handlePressCancelOrBackButton}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </StyledEnhancedCard>
    </EnhancedContainer>
  );
};

export default InventoryRepairForm;
