// External Dependencies
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridFilterModel,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import type {
  InventoryRepairIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventoryRepair';

// Internal Dependencies
import { ConfirmationDialog, DialogPermissionRequired } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import {
  isDistrictAdmin as selectIsDistrictAdmin,
  useHasPermission,
} from 'state/self/selectors';
import { useGetInventoryItemRepairList } from 'utils/api/inventoryRepair';
import { useIsOpen } from 'hooks/useIsOpen';
import EnhancedGridToolbar, { EnhancedGridToolbarProps } from 'components/shared/TableDataGrid/EnhancedGridToolbar';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  inventoryItemId: string;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.data-grid-container': {
    '& .MuiDataGrid-columnHeader': {
      fontWeight: 500,
    },

    margin: theme.spacing(1),
  },
  '.repair-history-data-grid': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  marginTop: theme.spacing(2),
}));

const NoRowsOverlay = (): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <Typography
      color="textSecondary"
      variant="body2"
    >
      This item has no repair history.
    </Typography>
  </Box>
);

const NoResultsOverlay = (): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <Typography
      color="textSecondary"
      variant="body2"
    >
      No results
    </Typography>
  </Box>
);

const inventoryRepairDefaultFilter = {
  items: [],
  quickFilterValues: [
    '',
  ],
};

const inventoryRepairDefaultSort: GridSortModel = [{ field: 'sentDate', sort: 'asc' }];

// Used for onToggleEditMode, a required prop for EnhancedGridToolbar
const noOp = (): void => {
  // Do nothing
};

const emptySelectedIds: DataGridProProps['rowSelectionModel'] = [];

// Component Definition
const InventoryRepairList = ({
  inventoryItemId,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const isDistrictAdmin = useSelector(selectIsDistrictAdmin);

  const canCreateInventoryRepairs = useHasPermission('inventoryRepairs', 'write');
  const canEditInventoryRepairs = useHasPermission('inventoryRepairs', 'edit');

  const [filteredRowIds, setFilteredRowIds] = useState<GridRowId[]>([]);
  const {
    isOpen: isRepairInProgressDialogOpen,
    toggleIsOpen: toggleRepairInProgressDialogOpen,
  } = useIsOpen();
  const {
    isOpen: isPermissionRequiredDialogOpen,
    toggleIsOpen: toggleIsPermissionRequiredDialogOpen,
  } = useIsOpen();

  const handleFilter = useCallback((rowIds: GridRowId[]) => {
    setFilteredRowIds(rowIds);
  }, []);

  const [
    gridFilterModel,
    setGridFilterModel,
  ] = useState<GridFilterModel>(inventoryRepairDefaultFilter);

  const handleChangeFilters = useCallback((model: GridFilterModel) => {
    setGridFilterModel(model);
  }, []);

  const [
    gridSortModel,
    setGridSortModel,
  ] = useState<GridSortModel>(inventoryRepairDefaultSort);

  const handleChangeSort = useCallback((model: GridSortModel) => {
    setGridSortModel(model);
  }, []);

  const {
    data: inventoryRepairData,
    isLoading,
  } = useGetInventoryItemRepairList(inventoryItemId);

  const hasRepairData = Boolean(inventoryRepairData?.data.data.length);

  const hasOutstandingRepair = inventoryRepairData?.data.data.some(
    (repair) => !repair.actualReturnDate,
  );

  const hasFilters = Boolean(
    gridFilterModel?.items.length || gridFilterModel?.quickFilterValues?.some(Boolean),
  );

  const handleNavigateToNewRepairPage = useCallback(() => {
    if (!canCreateInventoryRepairs) {
      return toggleIsPermissionRequiredDialogOpen();
    }

    if (hasOutstandingRepair) {
      return toggleRepairInProgressDialogOpen();
    }

    const newRepairBasePath = `/${PATHS.INVENTORY}/${inventoryItemId}/${PATHS.INVENTORY_REPAIRS_NEW}`;

    const newRepairPath = isDistrictAdmin
      ? `/${PATHS.DISTRICT_ADMIN}${newRepairBasePath}`
      : newRepairBasePath;

    return navigate(newRepairPath);
  }, [
    canCreateInventoryRepairs,
    hasOutstandingRepair,
    inventoryItemId,
    isDistrictAdmin,
    navigate,
    toggleIsPermissionRequiredDialogOpen,
    toggleRepairInProgressDialogOpen,
  ]);

  const buildPathToEditRepair = useCallback((repairId: string) => {
    const editRepairBasePath = `/${PATHS.INVENTORY}/${inventoryItemId}/${PATHS.INVENTORY_REPAIRS}/${repairId}/edit`;

    return isDistrictAdmin
      ? `/${PATHS.DISTRICT_ADMIN}${editRepairBasePath}`
      : editRepairBasePath;
  }, [inventoryItemId, isDistrictAdmin]);

  const handleNavigateToEditPageFromDialog = useCallback(() => {
    const repairId = inventoryRepairData?.data.data.find(
      (repair) => !repair.actualReturnDate,
    )?.id;

    if (!repairId) {
      return;
    }

    navigate(buildPathToEditRepair(repairId));
  }, [buildPathToEditRepair, inventoryRepairData, navigate]);

  const handleNavigateToEditPageFromActions = useCallback((
    inventoryRepair: InventoryRepairIndexResponseItem,
  ) => {
    navigate(buildPathToEditRepair(inventoryRepair.id));
  }, [buildPathToEditRepair, navigate]);

  const addButtonProps = useMemo(() => ({
    label: 'Repair',
    onClick: handleNavigateToNewRepairPage,
  }), [handleNavigateToNewRepairPage]);

  const actionsColumn = useMemo<GridColDef<InventoryRepairIndexResponseItem> | null>(() => {
    return createDataGridActionsColumn<InventoryRepairIndexResponseItem>([
      {
        action: handleNavigateToEditPageFromActions,
        icon: <EditIcon />,
        text: 'Edit',
      },
    ]);
  }, [handleNavigateToEditPageFromActions]);

  const columnArgs = useMemo(() => ({
    actionsColumn,
  }), [actionsColumn]);

  const columns = useColumns(columnArgs);

  const initialTableState = useMemo(() => ({
    pinnedColumns: {
      right: ['Actions'],
    },
  }), []);

  const slotProps = {
    toolbar: {
      addButtonProps,
      filteredIds: filteredRowIds,
      gridFilterModel,
      gridSortModel,
      isHydrating: isLoading,
      onFilter: handleFilter,
      onToggleEditMode: noOp,
      rows: inventoryRepairData?.data.data ?? [],
      selectedIds: emptySelectedIds,
      withSearch: true,
    } as EnhancedGridToolbarProps,
  };

  const dataGridElement = (
    <DataGridPro
      autoHeight
      className="repair-history-data-grid"
      columns={columns}
      disableRowSelectionOnClick
      hideFooter={!hasRepairData}
      initialState={initialTableState}
      localeText={{
        toolbarExport: 'Reports',
      }}
      onFilterModelChange={handleChangeFilters}
      onSortModelChange={handleChangeSort}
      rows={inventoryRepairData?.data.data ?? []}
      slotProps={slotProps}
      slots={{
        noResultsOverlay: hasFilters
          ? NoResultsOverlay
          : NoRowsOverlay,
        noRowsOverlay: hasFilters
          ? NoResultsOverlay
          : NoRowsOverlay,
        toolbar: EnhancedGridToolbar,
      }}
    />
  );

  const childElement = !inventoryRepairData
    ? <CircularProgress />
    : dataGridElement;

  return (
    <>
      <StyledRoot>
        <div className="data-grid-container">
          {childElement}
        </div>
      </StyledRoot>

      <ConfirmationDialog
        confirmButtonAction={canEditInventoryRepairs
          ? handleNavigateToEditPageFromDialog
          : toggleRepairInProgressDialogOpen}
        confirmButtonText={canEditInventoryRepairs ? 'Edit Repair' : 'OK'}
        declineButtonAction={toggleRepairInProgressDialogOpen}
        description={(
          <>
            <DialogContentText gutterBottom>
              This item has a repair in progress.
            </DialogContentText>

            <DialogContentText>
              To add a new repair,{' '}
              {!canEditInventoryRepairs && 'ask a director with the appropriate permissions to '}
              edit the current repair
              and add the <strong>Actual Return Date</strong>.
              Then create a new repair.
            </DialogContentText>
          </>
        )}
        handleClose={toggleRepairInProgressDialogOpen}
        hideDeclineButton={!canEditInventoryRepairs}
        open={isRepairInProgressDialogOpen}
        title="Repair In Progress"
        useCustomText
      />

      <DialogPermissionRequired
        isOpen={isPermissionRequiredDialogOpen}
        onClose={toggleIsPermissionRequiredDialogOpen}
      />
    </>
  );
};

export default InventoryRepairList;
