// External Dependencies
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import { OnboardingStepTitle } from 'components/shared';
import FindOrganization from 'pages/FindOrganization';
import MemberInfoForm from 'components/shared/MemberInfoForm';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import OnboardingProgress from './OnboardingProgress';

// Component Definition
const OnboardingMemberInfo = (): JSX.Element => {
  const theme = useTheme();

  const [showBottomContent, setShowBottomContent] = useState(false);

  const { loading, self } = useSelfQuery();

  const showFindOrganizationElement = Boolean(self?.pendingOrganizationMemberships.length
    || self?.districtMemberships.length
    || self?.userOrgData.length);

  const handlePressJoinAnotherOrgnization = useCallback(() => {
    setShowBottomContent(true);
  }, []);

  if (loading) {
    return <OnboardingProgress />;
  }

  const showMemberInfoForm = Boolean(
    (!self?.userOrgData?.length && !self?.districtMemberships?.length)
    || self?.matchedUnclaimedMembers?.length,
  );

  return (
    <>
      <OnboardingStepTitle title="Member Info" />

      <Box paddingX={theme.spacing(0.25)}>
        {showFindOrganizationElement && <FindOrganization />}

        <Collapse
          in={showFindOrganizationElement && showMemberInfoForm && !showBottomContent}
          mountOnEnter
          unmountOnExit
        >
          <Box marginTop={3}>
            <Button onClick={handlePressJoinAnotherOrgnization}>
              Join Another Organization
            </Button>
          </Box>
        </Collapse>

        <Collapse
          in={showMemberInfoForm && (!showFindOrganizationElement || showBottomContent)}
          mountOnEnter
          unmountOnExit
        >
          <Box marginY={theme.spacing(0.5)}>
            <MemberInfoForm isOnboarding />
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default OnboardingMemberInfo;
