// External Dependencies
import { useTheme } from 'styled-components';
import CardHeader from '@mui/material/CardHeader';

// Internal Dependencies
import EnhancedCardContent from './EnhancedCardContent';
import ShowPageDataDisplay from './ShowPageDataDisplay';

// Local Dependencies
import EnhancedDataCard from './EnhancedDataCard';

// Local Typings
interface Props {
  brand: string | null;
  label: string | null;
  model: string;
  serialNumber: string | null;
}

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const InventorySimpleDataCard = ({
  brand,
  label,
  model,
  serialNumber,
}:Props): JSX.Element => {
  const theme = useTheme();

  return (
    <EnhancedDataCard
      backgroundColor={theme.palette.inventory.dataCard}
    >
      <CardHeader title="Inventory item" />

      <EnhancedCardContent>
        <ShowPageDataDisplay
          label="Name"
          value={label}
        />
        <ShowPageDataDisplay
          label="Brand"
          value={brand}
        />
        <ShowPageDataDisplay
          label="Model"
          value={model}
        />
        <ShowPageDataDisplay
          label="Serial Number"
          value={serialNumber}
        />
      </EnhancedCardContent>
    </EnhancedDataCard>
  );
};

export default InventorySimpleDataCard;
