// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetInventoryItem } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import InventoryCheckoutCard from 'pages/Inventory/shared/InventoryCheckoutCard';
import InventoryForm from 'pages/Inventory/shared/InventoryForm';
import InventoryRepairCard from 'pages/Inventory/shared/InventoryRepairCard';

// Component Definition
const AdminInventoryShow = (): JSX.Element => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
      }}
      editButtonPath={`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}/edit`}
      error={error}
      isLoading={!data || loading}
    >
      {data && (
        <>
          <InventoryForm
            canUseDynamicFields
            inventoryItem={data?.inventoryItem}
            isAdmin
            readOnly
          />

          <InventoryCheckoutCard inventoryItemData={data.inventoryItem} />

          {id && <InventoryRepairCard inventoryItemId={id} />}

          {data?.inventoryItem && (
            <History
              id={data.inventoryItem.id}
              resource="inventoryItems"
            />
          )}
        </>
      )}
    </Page>
  );
};

export default AdminInventoryShow;
