// External Dependencies
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedButton,
  EnhancedDialog,
  Flex,
  PrestoLogoOnlySvg,
} from 'components/shared/index';
import { SHORT_APP_NAME } from 'utils/constants';
import { useClassLinkSso } from 'utils/api/auth';
import ClassLinkLogo from 'components/shared/SsoSignInDialog/ClassLinkLogo';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  switchToMemberId?: string;
}

// Component Definition
const ClassLinkSsoSignInDialog = ({
  isOpen,
  onClose,
  switchToMemberId,
}: Props): JSX.Element => {
  const { pathname, search } = useLocation();

  const { mutate: getClassLinkSsoUrl } = useClassLinkSso();

  const redirectPath = `redirect_path=${pathname}`;

  const handleClickClassLinkSso = useCallback(() => {
    getClassLinkSsoUrl({
      memberId: switchToMemberId,
      webAppQueryString: `${search || '?'}${redirectPath}`,
    });
  }, [
    getClassLinkSsoUrl,
    redirectPath,
    search,
    switchToMemberId,
  ]);

  return (
    <EnhancedDialog
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Sign in with ClassLink
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {switchToMemberId
            ? `Please sign in with your ClassLink account to access this organization in ${SHORT_APP_NAME}.`
            : 'Please sign in with your ClassLink account before continuing.'}
        </DialogContentText>

        <Flex
          flexWrap="nowrap"
          gap={1}
          justifyContent="center"
          marginBottom={4}
          marginTop={4}
        >
          <PrestoLogoOnlySvg sx={{ width: 60 }} />

          <AddIcon />

          <ClassLinkLogo width={200} />
        </Flex>

        {switchToMemberId && (
          <EnhancedAlert>
            SSO (single sign-on) is required for all administrators and directors in this district.
          </EnhancedAlert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>

        <EnhancedButton onClick={handleClickClassLinkSso}>
          Sign In
        </EnhancedButton>
      </DialogActions>
    </EnhancedDialog>
  );
};

export default ClassLinkSsoSignInDialog;
