// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { convertCentsToDollarsAllowNull } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { allOptionId } from 'components/shared/FinancialAccountSelect';
import { displayCell } from 'components/shared/TableV2';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';
import { useGetFinancialAccounts } from 'gql/queries';

export const useColumns = () => {
  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  return useMemo<GridColDef<GQL.IFinancialFundraiserCreditIndexItem>[]>(() => [
    {
      field: 'label',
      headerName: 'Name',
    },
    {
      field: 'financialAccountLabel',
      headerName: 'Account',
      type: 'singleSelect',
      valueGetter: (params) => params.row.financialAccountId ?? allOptionId,
      valueOptions: [
        {
          label: 'All Financial Accounts',
          value: allOptionId,
        },
        ...(financialAccountsData?.financialAccounts ?? []).map((account) => ({
          label: account.label,
          value: account.id,
        })),
      ],
    },
    {
      field: 'amountInCents',
      headerName: 'Amount',
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(
        convertCentsToDollarsAllowNull(params.value),
      ),
    },
    {
      field: 'assignedCount',
      headerName: 'Members Assigned',
      type: 'number',
    },
  ], [financialAccountsData]);
};
