// External Dependencies
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  Page,
} from 'components/shared';
import {
  SimpleInventoryItem,
  useGetSimpleInventoryItem,
} from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import { normalizeInventoryRepairFormPayload } from 'utils/lib/normalize_inventory_repair_form_payload';
import {
  isDistrictAdmin as selectIsDistrictAdmin,
} from 'state/self/selectors';
import { useCreateInventoryRepair } from 'utils/api/inventoryRepair';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import InventoryRepairForm from '../shared/InventoryRepairForm';
import type { NewInventoryRepairFormValues } from './types.d';

// Component Definition
const InventoryRepairsNew = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const isDistrictAdmin = useSelector(selectIsDistrictAdmin);

  const [inventoryItem, setInventoryItem] = useState<SimpleInventoryItem | null>(null);

  const {
    data: initialInventoryItem,
    loading: isInitialInventoryItemLoading,
  } = useGetSimpleInventoryItem(id!);

  const intialValues: NewInventoryRepairFormValues = {
    actualCostInCents: null,
    actualReturnDate: null,
    comments: null,
    estimatedCostInCents: null,
    estimatedReturnDate: null,
    priority: '1',
    sentDate: moment().toISOString(),
    // For now the serviceVendorId will be null
    serviceVendorId: null,
  };

  const inventoryShowPageBasePath = `/${PATHS.INVENTORY}/${id}`;
  const inventoryShowPagePath = isDistrictAdmin
    ? `/${PATHS.DISTRICT_ADMIN}${inventoryShowPageBasePath}`
    : inventoryShowPageBasePath;

  const navigateToInventoryShow = useCallback(() => {
    navigate(inventoryShowPagePath);
  }, [inventoryShowPagePath, navigate]);

  const {
    isLoading: isCreatingInventoryRepair,
    mutate: createInventoryRepair,
  } = useCreateInventoryRepair(id!);

  useEffect(() => {
    if (initialInventoryItem?.inventoryItem) {
      setInventoryItem(initialInventoryItem.inventoryItem);
    }
  }, [initialInventoryItem, setInventoryItem]);

  const handleSubmit = async (values: NewInventoryRepairFormValues) => {
    const normalizedValues = await normalizeInventoryRepairFormPayload({
      actualCostInCents: values.actualCostInCents,
      estimatedCostInCents: values.estimatedCostInCents,
      priority: values.priority,
    });

    const createRepairValues = {
      ...values,
      ...normalizedValues,
      inventoryItemId: id!,
    };

    await createInventoryRepair(
      createRepairValues,
      {
        onSuccess: () => {
          navigateToInventoryShow();
          dispatch(addNotification('Inventory Repair created', 'success'));
        },
      },
    );
  };

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Item',
        path: inventoryShowPagePath,
      }}
      isLoading={isInitialInventoryItemLoading || isCreatingInventoryRepair}
    >
      {inventoryItem && (
        <InventoryRepairForm
          intialValues={intialValues}
          inventoryItem={inventoryItem}
          onSubmit={handleSubmit}
          title="New Inventory Repair"
        />
      )}
    </Page>
  );
};

export default InventoryRepairsNew;
