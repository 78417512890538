// External Dependencies
import { useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';

// Internal Dependencies
import { dateColumn } from 'utils/lib/tableColumns';
import { displayFileSize } from 'utils/lib/display_file_size';
import type { DataGridColDef } from 'types/dataGrid';

// Hook Definition
export const useColumns = (extraColumns?: DataGridColDef<GQL.IFileIndexItem>[]) => {
  return useMemo(() => {
    const columns: GridColDef<GQL.IFileIndexItem>[] = [
      {
        field: 'fileName',
        headerName: 'Name',
        minWidth: 180,
      },
      dateColumn({
        field: 'createdAt',
        headerName: 'Upload Date',
      }),
      {
        field: 'createdBy',
        headerName: 'Uploaded By',
      },
      {
        field: 'fileSize',
        headerName: 'Size',
        type: 'number',
        valueFormatter: ({ value }) => displayFileSize(value),
      },
      {
        field: 'isPublic',
        headerName: 'Visible to All',
        type: 'boolean',
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [extraColumns]);
};
