// External Dependencies
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedContainer,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { useGetFinancialFundraiserCredit } from 'gql/queries';
import { useHasPermission } from 'state/self/selectors';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useTableQueryParams } from 'state/table/selectors';

// Local Dependencies
import FinancialFundraiserCreditMembersTable from '../shared/FinancialFundraiserCreditMembersTable';

// Component Definition
const FundraiserCreditsShowPage = (): JSX.Element => {
  const { id } = useParamsWithId();

  const canEditPayments = useHasPermission('payments', 'edit');

  const {
    data,
    error,
    loading,
  } = useGetFinancialFundraiserCredit(id!);

  const financialFundraiserCreditsParams = useTableQueryParams('financialFundraiserCredits');

  return (
    <Page
      backButtonProps={{
        label: 'Fundraiser Credits',
        path: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${financialFundraiserCreditsParams}`,
      }}
      editButtonPath={canEditPayments ? `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${id}/edit` : undefined}
      error={error}
      isLoading={!data || loading}
    >
      <EnhancedContainer sx={{ marginBottom: 2 }}>
        <ShowCard
          icon={CurrencyExchangeIcon}
          title="Fundraiser Credit"
        >
          <ShowPageDataDisplay
            label="Name"
            value={data?.financialFundraiserCredit.label}
          />

          <ShowPageDataDisplay
            label="Amount"
            value={displayPriceStringFromDollarAmount(
              convertCentsToDollars(data?.financialFundraiserCredit.amountInCents ?? 0),
            )}
          />

          <ShowPageDataDisplay
            label="Account"
            value={data?.financialFundraiserCredit.financialAccount?.label ?? 'All Financial Accounts'}
          />

          <ShowPageDataDisplay
            label="Items"
            labelComponent="span"
            value={(
              <List>
                {data?.financialFundraiserCredit.items.map((item) => {
                  const priceDisplay = displayPriceStringFromDollarAmount(
                    convertCentsToDollars(item.financialItem.priceInCents),
                  );

                  return (
                    <ListItem
                      dense
                      key={item.financialItem.id}
                    >
                      <ListItemText
                        primary={`${item.financialItem.label} — ${priceDisplay}`}
                        secondary={`Priority ${item.priority}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
            valueComponent="span"
          />

          <EnhancedAlert>
            Fundraiser credits are applied to members&apos;
            {' '}
            fees in the order of priority selected.
          </EnhancedAlert>
        </ShowCard>
      </EnhancedContainer>

      <FinancialFundraiserCreditMembersTable financialFundraiserCreditId={id!} />
    </Page>
  );
};

export default FundraiserCreditsShowPage;
