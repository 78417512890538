// External Dependencies
import { DistrictSsoTypes } from '@presto-assistant/api_types';

// Internal Dependencies
import ClassLinkSsoSignInDialog from './ClassLinkSsoSignInDialog';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  ssoTypeId?: number;
  switchToMemberId?: string;
}

// Component Definition
const SsoSignInDialog = ({
  isOpen,
  onClose,
  ssoTypeId,
  switchToMemberId,
}: Props): JSX.Element | null => {
  if (ssoTypeId === DistrictSsoTypes.ClassLink) {
    return (
      <ClassLinkSsoSignInDialog
        isOpen={isOpen}
        onClose={onClose}
        switchToMemberId={switchToMemberId}
      />
    );
  }

  return null;
};

export default SsoSignInDialog;
