// External Dependencies
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  userOrgRoleCounts: GQL.IUserOrgRoleCount[];
}

// Local Variables
// This provides enough space for the user org role name to stack nicely
const HEIGHT_MULTIPLIER = 48;

// Component Definition
const UserOrgRoleCounts = ({ userOrgRoleCounts }: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      common,
      mode,
      primary,
      text,
    },
  } = useTheme();

  const isDarkMode = mode === 'dark';
  const barColor = isDarkMode
    ? primary.dark
    : primary.main;

  const labelColor = isDarkMode
    ? text.primary
    : common.white;

  const axisColor = isDarkMode
    ? text.secondary
    : text.primary;

  const handleClickBar = useCallback((data) => {
    const datagridFilters = {
      items: [{
        field: 'primaryRole', operator: 'isAnyOf', value: [{ __typename: 'OrganizationRole', id: data.id, label: data.label }],
      }],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [''],
    };
    const queryString = `?asc=true&orderBy=firstName&limit=10&page=1&dataGridFilters=${JSON.stringify(datagridFilters)}`;

    navigate(`/${PATHS.STUDENTS}?${queryString}`);
  }, [navigate]);

  // Width on ResponsiveContainer must be set to 99% to allow chart to change on screen resizes
  // 100% width breaks it so that the chart does not change width
  return (
    <ResponsiveContainer
      height={userOrgRoleCounts.length * HEIGHT_MULTIPLIER}
      width="99%"
    >
      <BarChart
        data={userOrgRoleCounts}
        layout="vertical"
        margin={{ left: 32 }}
      >
        {/* Due to a bug in recharts, the vertical bar graph
        requires an x-axis, so we hide it */}
        <XAxis
          hide
          type="number"
        />
        <YAxis
          dataKey="label"
          tick={{
            fill: axisColor,
          }}
          type="category"
          width={110}
        />
        <Bar
          cursor="pointer"
          dataKey="total"
          fill={barColor}
          minPointSize={16}
          onClick={handleClickBar}
        >
          <LabelList
            dataKey="total"
            position="center"
            style={{ fill: labelColor, fontSize: 18, fontWeight: 600 }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>

  );
};

export default UserOrgRoleCounts;
