// External Dependencies
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { isDirector } from 'state/self/selectors';

// Local Dependencies
import ConfirmationDialog from './ConfirmationDialog';
import SupportLink from './SupportLink';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogPermissionRequired = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const isUserDirector = useSelector(isDirector);

  const personToContact = isUserDirector
    ? 'your district administrator for Presto'
    : 'your director';

  const permissionRequiredDescription = useMemo(() => (
    <>
      <DialogContentText paragraph>
        You do not have the required permissions needed to perform this action.
      </DialogContentText>

      <DialogContentText paragraph>
        Please contact
        {' '}
        {personToContact}
        {' '}
        to enable this permission.{' '}
      </DialogContentText>

      <DialogContentText>
        <SupportLink
          buttonText="Learn how to manage user permissions."
          slug="manage-user-permissions"
        />
      </DialogContentText>

    </>
  ), [personToContact]);

  return (
    <ConfirmationDialog
      confirmButtonAction={onClose}
      description={permissionRequiredDescription}
      handleClose={onClose}
      hideDeclineButton
      open={isOpen}
      title="Permission Required"
      useCustomText
    />
  );
};

export default DialogPermissionRequired;
