// External Dependencies
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedButton,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  Page,
} from 'components/shared';
import {
  getRedirectPath,
  useClassLinkSso,
  useClassLinkToken,
} from 'utils/api/auth';
import { useLogout } from 'utils/api';
import { useParseToken } from 'utils/cookies';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Component Definition
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const SsoClassLink = (): JSX.Element => {
  const parsedSearch = useParsedSearch();
  const { search } = useLocation();

  const [loadingTimeout, setLoadingTimeout] = useState(true);

  const navigate = useNavigate();

  const code = parsedSearch.code as string;

  const token = useParseToken();

  const hasSso = Boolean(token?.ssoTenantId);

  const {
    mutate: getClassLinkToken,
    status: classLinkTokenStatus,
  } = useClassLinkToken();

  const logout = useLogout();

  // if the user does not have a code, let's get one
  const { mutate: getClassLinkSsoUrl } = useClassLinkSso();

  const redirectPath = getRedirectPath();

  useEffect(() => {
    if (code) {
      getClassLinkToken({
        code,
      }, {
        onError: () => {
          navigate('/');
        },
      });
    } else {
      // if we don't have a code, let's log the user out and get a code
      logout()
        .then(() => {
          let webAppQueryString = search || '?';

          if (redirectPath && redirectPath !== '/') {
            webAppQueryString += redirectPath;
          }

          getClassLinkSsoUrl({
            webAppQueryString,
          });
        });
    }
  }, [
    code,
    getClassLinkToken,
    getClassLinkSsoUrl,
    logout,
    navigate,
    redirectPath,
    search,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingTimeout(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!loadingTimeout && !hasSso) {
    return (
      <Page>
        <EnhancedContainer sx={{ marginTop: 12 }}>
          <EnhancedCard>
            <EnhancedCardContent>
              <EnhancedAlert
                severity="error"
              >
                There was an issue linking your ClassLink account. Please try again.
              </EnhancedAlert>
            </EnhancedCardContent>

            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <EnhancedButton
                href={redirectPath}
              >
                Back to Sign In
              </EnhancedButton>
            </CardActions>
          </EnhancedCard>
        </EnhancedContainer>
      </Page>
    );
  }

  return (
    <Page isLoading={classLinkTokenStatus === 'loading' || loadingTimeout}>
      <EnhancedContainer sx={{ marginTop: 12 }}>
        <EnhancedCard>
          <EnhancedCardContent>
            <EnhancedAlert
              severity="success"
            >
              Successfully linked your ClassLink account.
            </EnhancedAlert>
          </EnhancedCardContent>

          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <EnhancedButton
              href={redirectPath}
            >
              Continue
            </EnhancedButton>
          </CardActions>
        </EnhancedCard>
      </EnhancedContainer>
    </Page>
  );
};

export default SsoClassLink;
