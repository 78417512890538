// External Dependencies
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';

// Local Typings
interface NormalizeFormArgs {
  actualCostInCents: string | null;
  estimatedCostInCents: string | null;
  priority: string | null;
}

interface ReturnValues {
  actualCostInCents: number | null;
  estimatedCostInCents: number | null;
  priority: number | null;
}

// Util Definition
export const normalizeInventoryRepairFormPayload = async ({
  actualCostInCents,
  estimatedCostInCents,
  priority,
}: NormalizeFormArgs): Promise<ReturnValues> => {
  const normalizedValues = {
    actualCostInCents: actualCostInCents
      ? convertDollarsToCents(Number(actualCostInCents))
      : null,
    estimatedCostInCents: estimatedCostInCents
      ? convertDollarsToCents(Number(estimatedCostInCents))
      : null,
    priority: priority
      ? Number(priority)
      : null,
  };

  return normalizedValues;
};
