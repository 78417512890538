// External Dependencies
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  '.checkbox': {
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
    color: theme.palette.stripeBlue['600'],
  },
  '.checked': {
    '&.Mui-checked': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
      },
      color: theme.palette.stripeBlue['500'],
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
  },
  '.formControlLabelLabel': {
    marginLeft: 2,
    textTransform: 'capitalize',
  },
  '.formControlLabelRoot': {
    [theme.breakpoints.down('sm')]: {
      minWidth: '25%',
    },
    margin: `0 ${theme.spacing(2)}`,
  },
  '.formGroup': {
    display: 'flex',
    flexDirection: 'row',
  },
  '.formGroupLabel': {
    fontWeight: 500,
  },
}));

// Local Typings
export type PermissionAction = 'read' | 'write' | 'edit' | 'delete';
interface Props {
  actions?: PermissionAction[];
  dense?: boolean;
  label: string;
  onChange: (permissionId: number) => void;
  permissions?: GQL.IPermissionActions;
  selectedPermissionIds: number[];
  selfPermissionIds: number[];
}

// Component Definition
const PermissionFormGroup = ({
  actions = ['read', 'write', 'edit', 'delete'],
  dense,
  label,
  onChange,
  permissions,
  selfPermissionIds,
  selectedPermissionIds,
}: Props): JSX.Element | null => {
  if (!permissions || actions.length === 0) {
    return null;
  }

  const handleChange = (permissionId: number) => () => {
    onChange(permissionId);
  };

  return (
    <StyledBox
      marginX={2}
      marginY={dense ? 0 : 2}
    >
      <Typography
        className="formGroupLabel"
        gutterBottom={!dense}
      >
        {label}
      </Typography>

      <FormGroup className="formGroup">
        {actions.map((action) => {
          const { permissionId } = permissions[action];

          return (
            <FormControlLabel
              aria-label={`${action} ${label}`}
              className="formControlLabelRoot"
              classes={{ label: 'formControlLabelLabel' }}
              control={(
                <Checkbox
                  checked={selectedPermissionIds.includes(permissionId)}
                  classes={{
                    checked: 'checked',
                    colorSecondary: 'checkbox',
                  }}
                  color="primary"
                  disabled={!selfPermissionIds.includes(permissionId)}
                  edge="start"
                  onChange={handleChange(permissionId)}
                />
              )}
              key={action}
              label={action}
            />
          );
        })}
      </FormGroup>
    </StyledBox>
  );
};

export default PermissionFormGroup;
