// External Dependencies
import {
  FC, useCallback, useMemo,
} from 'react';
import { UserRoles } from '@presto-assistant/api_types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { GET_DASHBOARD_METRICS } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DangerZone, { DangerZoneItemProps } from 'components/shared/DangerZone';
import RemoveAuthMemberDialog from './RemoveAuthMemberDialog';
import SwitchDirectorToParentDialog from './SwitchDirectorToParentDialog';
import SwitchParentAndStudentRolesDialog from './SwitchParentAndStudentRolesDialog';

// Local Dependencies
import RemoveMemberDialog from './RemoveMemberDialog';

// Local Typings
interface Props {
  authUserEmail?: string | null;
  isDirector?: boolean;
  memberEmail?: string;
  memberId?: string;
  memberRoleId?: UserRoles;
  organizationMembershipId: string;
  returnToOnSuccess: string;
}

// Local Variables
const USER_ROLES = {
  DIRECTOR: 'director',
  PARENT: 'parent',
  STUDENT: 'student',
};

// Component Definition
const PeopleDangerZone: FC<Props> = ({
  authUserEmail,
  isDirector,
  memberEmail,
  memberId,
  memberRoleId,
  organizationMembershipId,
  returnToOnSuccess,
}) => {
  const navigate = useNavigate();
  const directorParams = useSelector(tableQueryParams('directors'));

  const {
    isOpen: isRemoveUserConfirmationDialogOpen,
    toggleIsOpen: toggleRemoveUserConfirmationDialogIsOpen,
  } = useIsOpen();
  const {
    isOpen: isSwitchParentAndStudentRolesConfirmationDialogIsOpen,
    toggleIsOpen: toggleSwitchParentAndStudentRolesConfirmationDialogIsOpen,
  } = useIsOpen();
  const {
    isOpen: isSwitchDirectorToParentConfirmationDialogIsOpen,
    toggleIsOpen: toggleSwitchDirectorToParentConfirmationDialogIsOpen,
  } = useIsOpen();
  const {
    isOpen: isRemoveAuthMemberDialogOpen,
    toggleIsOpen: toggleRemoveAuthMemberDialogOpen,
  } = useIsOpen();

  const canRemoveMembers = useSelector(hasPermission('users', 'delete'));
  const isMobileWidth = useSelector(isMobileScreenSize);
  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const handleCompleted = useCallback(() => {
    navigate(returnToOnSuccess);
  }, [navigate, returnToOnSuccess]);

  const items: DangerZoneItemProps[] = [];

  if (canRemoveMembers) {
    items.push({
      buttonText: isMobileWidth ? 'Remove' : 'Remove member',
      description: 'Deactivate member in this organization. This does not delete their Presto member profile entirely.',
      onClick: toggleRemoveUserConfirmationDialogIsOpen,
      title: 'Remove member from organization',
    });
  }

  if (canEditUsers && authUserEmail && memberEmail && memberId) {
    items.push({
      buttonText: isMobileWidth ? 'Unlink' : 'Unlink Email',
      description: "Separate the Login Email from the member, such as when a parent claimed their child's member.",
      onClick: toggleRemoveAuthMemberDialogOpen,
      title: 'Unlink Account Email',
    });
  }

  const { oppositeRoleText, userRoleText } = useMemo(() => {
    if (memberRoleId === UserRoles.Student) {
      return { oppositeRoleText: USER_ROLES.PARENT, userRoleText: USER_ROLES.STUDENT };
    }

    return { oppositeRoleText: USER_ROLES.STUDENT, userRoleText: USER_ROLES.PARENT };
  }, [memberRoleId]);

  // We only allow switching the adult/student role for parent/student members.
  // The director's `DangerZone` won't send this prop in.
  if (memberRoleId && !isDirector) {
    items.unshift({
      buttonText: 'Switch role',
      description: `Switch this member from a ${userRoleText} to a ${oppositeRoleText}.`,
      onClick: toggleSwitchParentAndStudentRolesConfirmationDialogIsOpen,
      title: `Switch to a ${oppositeRoleText}`,
    });
  }

  if (isDirector) {
    items.unshift({
      buttonText: 'Switch role',
      description: "Switch this member from a director to a parent. This action will retain all of the member's current permission levels but restrict them from viewing organization-level settings.",
      onClick: toggleSwitchDirectorToParentConfirmationDialogIsOpen,
      title: 'Switch to a parent',
    });
  }

  return (
    <>
      <Box mt={4}>
        <DangerZone items={items} />
      </Box>

      <SwitchParentAndStudentRolesDialog
        isOpen={isSwitchParentAndStudentRolesConfirmationDialogIsOpen}
        memberId={memberId}
        memberRoleId={memberRoleId}
        onClose={toggleSwitchParentAndStudentRolesConfirmationDialogIsOpen}
        returnToOnSuccess={returnToOnSuccess}
      />

      <SwitchDirectorToParentDialog
        isOpen={isSwitchDirectorToParentConfirmationDialogIsOpen}
        memberId={memberId}
        onClose={toggleSwitchDirectorToParentConfirmationDialogIsOpen}
        returnToOnSuccess={`/${PATHS.DIRECTORS}${directorParams}`}
      />

      {memberId && (
        <RemoveMemberDialog
          isOpen={isRemoveUserConfirmationDialogOpen}
          memberId={memberId}
          onClose={toggleRemoveUserConfirmationDialogIsOpen}
          onMemberDeleted={handleCompleted}
          organizationMembershipId={organizationMembershipId}
          refetchQueries={[{ query: GET_DASHBOARD_METRICS }]}
        />
      )}

      {authUserEmail && memberEmail && memberId && (
        <RemoveAuthMemberDialog
          authUserEmail={authUserEmail}
          isOpen={isRemoveAuthMemberDialogOpen}
          memberEmail={memberEmail}
          memberId={memberId}
          onClose={toggleRemoveAuthMemberDialogOpen}
        />
      )}
    </>
  );
};

export default PeopleDangerZone;
