// External Dependencies
import { useCallback, useEffect, useMemo } from 'react';

// Internal Dependencies
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { getAllowedPermissionIds } from './hooks';
import PermissionEditPageUI from './PermissionEditPageUI';

// Local Typings
interface Props {
  isDirector: boolean;
  isLoading: boolean;
  mayEditPermissions: boolean;
  onSave: () => void;
  onUpdateMayEditPermissions: (allowedToEdit: boolean) => void;
  onUpdatePermissionIds: (updatedPermissionIds: number[]) => void;
  organizationName: string | undefined;
  permissionIds: number[];
  permissions: GQL.IUser['permissions'];
}

// Component Definition
const PermissionEditPage = ({
  isDirector,
  isLoading,
  mayEditPermissions,
  onSave,
  onUpdateMayEditPermissions,
  onUpdatePermissionIds,
  organizationName,
  permissionIds,
  permissions,
}: Props): JSX.Element => {
  const onToggleEditPermissionsCheckbox = () => {
    onUpdateMayEditPermissions(!mayEditPermissions);
  };

  const handleChange = useCallback((permissionId: number) => {
    if (permissionIds.includes(permissionId)) {
      const index = permissionIds.findIndex((pId) => pId === permissionId);

      const permissionIdsCopy = [...permissionIds];

      permissionIdsCopy.splice(index, 1);

      onUpdatePermissionIds(permissionIdsCopy);
    } else {
      onUpdatePermissionIds([
        ...permissionIds,
        permissionId,
      ]);
    }
  }, [onUpdatePermissionIds, permissionIds]);

  const { self } = useSelfQuery();

  const selfPermissionIds: number[] = useMemo(() => {
    if (self?.permissions) {
      return getAllowedPermissionIds(self?.permissions);
    }
    return [];
  }, [self]);

  useEffect(() => {
    if (!permissions) {
      onUpdatePermissionIds([]);
    } else {
      const newPermissionIds = getAllowedPermissionIds(permissions);
      onUpdatePermissionIds(newPermissionIds);
    }
  }, [onUpdatePermissionIds, permissions]);

  return (
    <PermissionEditPageUI
      isDirector={isDirector}
      isLoading={isLoading}
      mayEditPermissions={mayEditPermissions}
      onChangePermission={handleChange}
      onSave={onSave}
      onToggleEditPermissionsCheckbox={onToggleEditPermissionsCheckbox}
      organizationName={organizationName}
      permissionIds={permissionIds}
      permissions={permissions}
      selfPermissionIds={selfPermissionIds}
    />
  );
};

export default PermissionEditPage;
