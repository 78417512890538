// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import type {
  InventoryRepairIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventoryRepair';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { currencyFromCentsColumn, dateColumn } from 'utils/lib/tableColumns';
import { renderInventoryRepairStatusCell } from 'pages/InventoryRepairs/shared/utils';
import { repairPriorityOptions } from 'pages/InventoryRepairs/shared/InventoryRepairPrioritySelect';

// Local Variables
const repairStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
];

export const useColumns = (args: {
  actionsColumn: GridColDef<InventoryRepairIndexResponseItem> | null;
}) => {
  return useMemo(() => {
    const columns: GridColDef<InventoryRepairIndexResponseItem>[] = [
      // Status is based on the actualReturnDate
      // - Open if actualReturnDate is null
      // - Closed if actualReturnDate is not null
      {
        field: 'status',
        flex: 1,
        headerName: 'Status',
        minWidth: 96,
        renderCell: (params) => {
          return renderInventoryRepairStatusCell(params.row.actualReturnDate);
        },
        type: 'singleSelect',
        valueGetter: (params) => {
          return params.row.actualReturnDate ? 'closed' : 'open';
        },
        valueOptions: repairStatusOptions,
      },
      dateColumn({
        field: 'sentDate',
        headerName: 'Sent Date',
      }),
      dateColumn({
        field: 'actualReturnDate',
        headerName: 'Actual Return Date',
      }),
      // {
      //   field: 'serviceVendorLabel',
      //   flex: 1,
      //   headerName: 'Service Vendor',
      //   minWidth: 180,
      // },
      {
        field: 'comments',
        flex: 1,
        headerName: 'Comments',
        minWidth: 144,
      },
      dateColumn({
        field: 'estimatedReturnDate',
        headerName: 'Estimated Return Date',
      }),
      {
        field: 'priority',
        flex: 1,
        headerName: 'Priority',
        minWidth: 64,
        type: 'singleSelect',
        valueOptions: repairPriorityOptions,
      },
      currencyFromCentsColumn({
        field: 'estimatedCostInCents',
        headerName: 'Estimated Cost',
        minWidth: 160,
      }),
      currencyFromCentsColumn({
        field: 'actualCostInCents',
        headerName: 'Actual Cost',
        minWidth: 160,
      }),
    ];

    if (args.actionsColumn) {
      columns.push(args.actionsColumn as DataGridColDef<InventoryRepairIndexResponseItem>);
    }

    return columns;
  }, [args.actionsColumn]);
};
