// External Dependencies
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Dependencies
import EnhancedAlert from '../EnhancedAlert';
import PermissionFormGroup from './PermissionFormGroup';
import SaveButton from '../SaveButton';
import SupportLink from '../SupportLink';

// Local Typings
interface Props {
  isDirector: boolean;
  isLoading: boolean;
  mayEditPermissions: boolean;
  onChangePermission: (permissionId: number) => void;
  onSave: () => void;
  onToggleEditPermissionsCheckbox: () => void;
  organizationName: string | undefined;
  permissionIds: number[];
  permissions: GQL.IUser['permissions'];
  selfPermissionIds: number[];
}

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  '.checkbox': {
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
    color: theme.palette.stripeBlue['600'],
  },
  '.checked': {
    '&.Mui-checked': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
      },
      color: theme.palette.stripeBlue['500'],
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
  },
  '.editOtherUsers': {
    margin: `0 ${theme.spacing(2)}`,
  },
  '.label': {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));

// Component Definition
const PermissionEditPageUI = ({
  isDirector,
  isLoading,
  mayEditPermissions,
  onChangePermission,
  onSave,
  onToggleEditPermissionsCheckbox,
  organizationName,
  permissionIds,
  permissions,
  selfPermissionIds,
}: Props): JSX.Element => {
  return (
    <>
      <StyledSection>
        <Collapse in={Boolean(organizationName)}>
          {organizationName && (
            <EnhancedAlert sx={{ marginBottom: 1 }}>
              These permissions apply only to{' '}
              <strong>
                {organizationName}
              </strong>
            </EnhancedAlert>
          )}
        </Collapse>

        <EnhancedAlert>
          You are unable to grant permissions that you do not have yourself.
          For more information on managing permissions, check out{' '}
          <SupportLink
            buttonText="this article"
            slug="manage-user-permissions/#editing-permission-comes-from-the-district-administrator"
          />
          .
        </EnhancedAlert>

        {isDirector && (
          <>
            <Box m={2}>
              <Typography className="label">Overall</Typography>

              <FormControl className="editOtherUsers">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={mayEditPermissions}
                      classes={{
                        checked: 'checked',
                        colorSecondary: 'checkbox',
                      }}
                      color="primary"
                      onChange={onToggleEditPermissionsCheckbox}
                    />
                  )}
                  label="Can edit permissions for other users?"
                />
              </FormControl>
            </Box>

            <Divider />
          </>
        )}

        <PermissionFormGroup
          actions={['write']}
          label="Email Members"
          onChange={onChangePermission}
          permissions={permissions?.emailMembers}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="File Uploads"
          onChange={onChangePermission}
          permissions={permissions?.s3Uploads}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Finances"
          onChange={onChangePermission}
          permissions={permissions?.finances}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Financial Accounts"
          onChange={onChangePermission}
          permissions={permissions?.financialAccounts}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Groups"
          onChange={onChangePermission}
          permissions={permissions?.groups}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          actions={['write', 'read', 'delete']}
          label="Inventory (All Fields)"
          onChange={onChangePermission}
          permissions={permissions?.inventory}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />

        <Box
          marginLeft={4}
          paddingBottom={2}
        >
          <PermissionFormGroup
            actions={['edit']}
            dense
            label="Basic Info"
            onChange={onChangePermission}
            permissions={permissions?.inventory}
            selectedPermissionIds={permissionIds}
            selfPermissionIds={selfPermissionIds}
          />

          <PermissionFormGroup
            actions={['edit']}
            dense
            label="Status Info"
            onChange={onChangePermission}
            permissions={permissions?.inventoryStatusInfo}
            selectedPermissionIds={permissionIds}
            selfPermissionIds={selfPermissionIds}
          />

          <PermissionFormGroup
            actions={['edit']}
            dense
            label="Location Info"
            onChange={onChangePermission}
            permissions={permissions?.inventoryLocationInfo}
            selectedPermissionIds={permissionIds}
            selfPermissionIds={selfPermissionIds}
          />

          <PermissionFormGroup
            actions={['edit']}
            dense
            label="Purchase Info"
            onChange={onChangePermission}
            permissions={permissions?.inventoryPurchaseInfo}
            selectedPermissionIds={permissionIds}
            selfPermissionIds={selfPermissionIds}
          />

          <PermissionFormGroup
            actions={['edit']}
            dense
            label="Dynamic Fields"
            onChange={onChangePermission}
            permissions={permissions?.inventoryDynamicFields}
            selectedPermissionIds={permissionIds}
            selfPermissionIds={selfPermissionIds}
          />
        </Box>

        <Divider />

        <PermissionFormGroup
          actions={['read', 'write', 'edit']}
          label="Inventory Checkouts"
          onChange={onChangePermission}
          permissions={permissions?.inventoryCheckouts}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          actions={['read', 'write', 'edit']}
          label="Inventory Repairs"
          onChange={onChangePermission}
          permissions={permissions?.inventoryRepairs}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Library"
          onChange={onChangePermission}
          permissions={permissions?.library}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          actions={['read', 'write', 'edit']}
          label="Payments"
          onChange={onChangePermission}
          permissions={permissions?.payments}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Uniform Checkouts"
          onChange={onChangePermission}
          permissions={permissions?.uniformCheckouts}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          label="Uniforms"
          onChange={onChangePermission}
          permissions={permissions?.uniforms}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
        <Divider />

        <PermissionFormGroup
          actions={['read', 'write', 'edit', 'delete']}
          label="Users"
          onChange={onChangePermission}
          permissions={permissions?.users}
          selectedPermissionIds={permissionIds}
          selfPermissionIds={selfPermissionIds}
        />
      </StyledSection>

      <DialogActions>
        <SaveButton
          isSaving={isLoading}
          onClick={onSave}
        >
          Save
        </SaveButton>
      </DialogActions>
    </>
  );
};

export default PermissionEditPageUI;
